
import {Input, Button} from 'antd'
import { useState } from 'react';

const DeliveryRateCalculator = ({ ratePerMile, surchargePercentage, fixedRate, fixedRateMiles }) => {
  const [ price, setPrice ] = useState(0)
  const [ miles, setMiles ] = useState(0)

  const handleCalculate = () => {
    let price = 0
    if (miles < fixedRateMiles) {
      price = fixedRate
    } else {
      price = fixedRate + ratePerMile * (miles - fixedRateMiles)
    }
    price = price * surchargePercentage
    setPrice(price)
  }

  return (
    <div>
      <div className="d-flex align-items-center">
        <Input
          type="number"
          min={0}
          suffix="miles"
          value={miles}
          onChange={(e) => setMiles(e.target.value)}
          className="me-2"
        />
        <Button
          className="mx-2"
          type="primary"
          onClick={() => handleCalculate()}
        >
          get rate
        </Button>
        <Button
          onClick={() => {
            setMiles(0);
            setPrice(0);
          }}
        >
          clear
        </Button>
      </div>
      <div className="my-2 text-center">
        <h4 className="p-2 border border-secondary rounded d-inline-block text-primary">
          $ {price}
        </h4>
      </div>
    </div>
  );
}

export default DeliveryRateCalculator
