import { Outlet } from "react-router-dom";
// import { useContext } from "react";
// import AuthContext from "../context/AuthContext";
// import NavBar from "../components/NavBar";

const AppLayout = () => {
  // let { isLoggedIn } = useContext(AuthContext);
  return (
    <>
    {/* <div className="container"> */}
      {/* <NavBar /> */}
      <Outlet />
    {/* </div> */}
    </>
  )
}

export default AppLayout;
