import { Divider } from "antd";
import PasswordLogin from "./PasswordLogin";
import OTPLogin from "../OTP/OTPLogin";
import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css"
import AuthContext from "../../context/AuthContext";

const Login = () => {
  const { setAlertClassName, isLoggedIn } = useContext(AuthContext);
  const [loginMethod, setLoginMethod] = useState("otp")
  const navigate = useNavigate()

  if (isLoggedIn) {
    navigate("/tasks");
  }

  return (
    <div className="m-2">
      <h2 className="text-center my-4">Log In</h2>
      <Divider />
      {loginMethod === "otp" ? <OTPLogin /> : <PasswordLogin />}
      <div className="text-center text-primary">
        {loginMethod === "otp" ? (
          <p
            className="d-inline-block login-method-text"
            onClick={() => {
              setLoginMethod("password");
              setAlertClassName("d-none");
            }}
          >
            Use email and password instead
          </p>
        ) : (
          <p
            className="d-inline-block login-method-text"
            onClick={() => {
              setLoginMethod("otp");
              setAlertClassName("d-none");
            }}
          >
            Use one-time code instead
          </p>
        )}
      </div>
      <div className="text-center mt-4">
        <em>
          New to the Portal?{" "}
          <Link to="/signup" onClick={() => setAlertClassName("d-none")}>
            Create an account
          </Link>
        </em>
      </div>
    </div>
  );
};

export default Login;
