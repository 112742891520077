import { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import {
  Button,
  Form,
  Input,
  Select,
  Divider,
  Spin,
  Modal,
  message,
} from "antd";
import { ArrowLeftOutlined, CopyOutlined } from "@ant-design/icons";
import { useFetchCities } from "../../utils/LocationUtils";
import { CopyToClipboard } from '../../utils/FormUtils';

const DriverDetails = () => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;
  const navigate = useNavigate();

  const { id } = useParams();
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const [loading, setLoading] = useState(true);

  const [allowEdit, setAllowEdit] = useState(false);

  const { cities, cityOptions } = useFetchCities();
  const [oldCities, setOldCities] = useState([]);
  const [citiesToAdd, setCitiesToAdd] = useState([]);
  const [citiesToRemove, setCitiesToRemove] = useState([]);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // fetch driver details
  useEffect(() => {
    const fetchDriverData = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      let url = `${process.env.REACT_APP_BACKEND}/v1/drivers/${id}`;

      const setServiceAreasData = (citiesData) => {
        if (cities.length === 0) {
          return [];
        }
        let cityIndices = [];
        for (let i = 0; i < citiesData.length; i++) {
          let index = cities.findIndex((city) => city.city === citiesData[i]);
          cityIndices.push(index);
        }
        console.log(cityIndices);
        return cityIndices;
      };

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        console.log(data);
        form.setFieldsValue(data);
        // set multi select values
        form.setFieldsValue({
          cities: setServiceAreasData(data.cities),
        });
        setOldCities(data.cities);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    fetchDriverData();
  }, [accessToken, id, form, cities, allowEdit]);

  const handleCityChange = (value) => {
    const newCities = value.map((index) => cities[index]["city"]);
    const addedCities = newCities.filter((city) => !oldCities.includes(city));
    const removedCities = oldCities.filter((city) => !newCities.includes(city));
    setCitiesToAdd(addedCities);
    setCitiesToRemove(removedCities);
  };

  const handleSubmit = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + accessToken);

    let values = form.getFieldsValue();
    let payload = {
      driverId: parseInt(id),
      contactName: values.contactName.trim(),
      companyName: values.companyName ? values.companyName.trim() : null,
      email: values.email ? values.email : null,
      phone: values.phone ? values.phone : null,
      notes: values.notes ? values.notes.trim() : null,
      ratePerMile: parseFloat(values.ratePerMile),
      fixedRate: parseInt(values.fixedRate),
      fixedRateMiles: parseInt(values.fixedRateMiles),
      surchargePercentage: parseFloat(values.surchargePercentage),
      citiesToAdd: citiesToAdd,
      citiesToRemove: citiesToRemove,
    };

    let requestOptions = {
      method: "PUT",
      headers: headers,
      credentials: "include",
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/drivers/${id}`;

    try {
      const res = await fetch(url, requestOptions);
      if (res.ok) {
        message.success("Driver info updated successfully");
        setAllowEdit(false);
      } else {
        message.error("Failed to update driver info");
      }
    } catch (error) {
      message.error("Failed to update driver");
    } finally {
      setAllowEdit(false);
    }
  };

  const handleDeleteDriver = async () => {
    Modal.confirm({
      title: "Are you sure you want to delete this driver?",
      content: "This action cannot be undone.",
      centered: true,
      onOk: () => {
        const deleteDriver = async () => {
          const headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Authorization", "Bearer " + accessToken);

          let requestOptions = {
            method: "DELETE",
            headers: headers,
            credentials: "include",
          };

          const url = `${process.env.REACT_APP_BACKEND}/v1/drivers/${id}`;

          try {
            await fetch(url, requestOptions);
            message.success("Driver deleted successfully.");
            navigate("/drivers");
          } catch (err) {
            message.error("Failed to delete driver.");
            console.log(err);
          }
        };

        deleteDriver();
      }
    });
  };

  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : (
        <div className="m-2">
          <Button>
            <Link to="/drivers" style={{ textDecoration: "none" }}>
              <ArrowLeftOutlined /> back
            </Link>
          </Button>
          <Divider />
          <div className="mb-4 d-flex flex-wrap align-items-center justify-content-center">
            {allowEdit && (
              <div className="d-flex justify-content-around">
                <Button className="mx-2" type="primary" onClick={handleSubmit}>
                  Update
                </Button>
                <Button onClick={() => setAllowEdit(false)}>Cancel</Button>
              </div>
            )}
            {!allowEdit && (
              <div className="d-flex justify-content-around">
                <Button className="mx-2" onClick={() => setAllowEdit(true)}>
                  Edit Info
                </Button>
              </div>
            )}
          </div>
          <Form
            form={form}
            name="edit-driver"
            onFinish={handleSubmit}
            disabled={!allowEdit}
          >
            <div className="justify-content-around mx-4">
              <Form.Item
                name="contactName"
                label="Contact Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the name of the contact.",
                  },
                  {
                    pattern: new RegExp("^[a-zA-Z0-9 '-]*$"),
                    message: "Only alphabet characters are allowed",
                  },
                ]}
              >
                <Input placeholder="Contact Name" />
              </Form.Item>

              <Form.Item
                name="companyName"
                label="Company Name"
                rules={[
                  {
                    pattern: new RegExp("^[a-zA-Z0-9 '&-]*$"),
                    message: "Only alphabet characters are allowed",
                  },
                ]}
              >
                <Input placeholder="Company Name" />
              </Form.Item>

              <div className="d-flex flex-wrap justify-content-between">
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      type: "email",
                      message: "Not a valid email",
                    },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    suffix={
                      form.getFieldValue("email") !== "" && (
                        <Button
                          key="copy"
                          size="small"
                          onClick={() =>
                            CopyToClipboard(form, message, "email")
                          }
                          icon={<CopyOutlined />}
                          disabled={false}
                        />
                      )
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="phone"
                  label="Phone Number"
                  rules={[
                    {
                      max: 10,
                      message: "Phone number can't be more than 10 characters",
                    },
                    {
                      pattern: new RegExp("^[0-9]+$"),
                      message: "Only numbers are allowed",
                    },
                  ]}
                >
                  <Input
                    placeholder="Phone Number"
                    suffix={
                      form.getFieldValue("phone") !== "" && (
                        <Button
                          key="copy"
                          size="small"
                          onClick={() =>
                            CopyToClipboard(form, message, "phone")
                          }
                          icon={<CopyOutlined />}
                          disabled={false}
                        />
                      )
                    }
                  />
                </Form.Item>
              </div>

              <Form.Item
                name="cities"
                label="Service Areas"
                rules={[
                  {
                    required: true,
                    message: "Please select at least a city.",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  showSearch
                  placeholder="Select service areas"
                  allowClear={true}
                  options={cityOptions}
                  filterOption={filterOption}
                  onChange={(value) => handleCityChange(value)}
                />
              </Form.Item>

              <div className="d-flex flex-wrap justify-content-between">
                <Form.Item
                  name="ratePerMile"
                  label="Rate per mile"
                  rules={[
                    {
                      pattern: new RegExp("([0-9]*[.])?[0-9]+"),
                      message: "Please enter number.",
                    },
                  ]}
                >
                  <Input placeholder="Rate Per Mile" allowClear />
                </Form.Item>

                <Form.Item
                  name="surchargePercentage"
                  label="Surcharge Percentage"
                  rules={[
                    {
                      pattern: new RegExp("([0-9]*[.])?[0-9]+"),
                      message: "Please enter number.",
                    },
                  ]}
                >
                  <Input placeholder="Surcharge Percentage" allowClear />
                </Form.Item>
              </div>

              <div className="d-flex flex-wrap justify-content-between">
                <Form.Item
                  name="fixedRate"
                  label="Fixed Rate"
                  rules={[
                    {
                      pattern: new RegExp("[0-9]"),
                      message: "Please enter number.",
                    },
                  ]}
                >
                  <Input placeholder="Fixed Rate" allowClear />
                </Form.Item>

                <Form.Item
                  name="fixedRateMiles"
                  label="Fixed Rate Miles"
                  rules={[
                    {
                      pattern: new RegExp("([0-9]*[.])?[0-9]+"),
                      message: "Please enter number.",
                    },
                  ]}
                >
                  <Input placeholder="Fixed Rate Miles" allowClear />
                </Form.Item>
              </div>

              <Form.Item
                name="notes"
                label="Notes"
                rules={[
                  {
                    max: 200,
                    message: "Can't exceed 200 characters",
                  },
                ]}
              >
                <TextArea allowClear autoSize />
              </Form.Item>
            </div>
          </Form>

          <Divider />
          <div className="mt-2 mb-4 mx-4 d-flex flex-column align-items-end">
            <Button type="dashed" danger onClick={handleDeleteDriver}>
              Delete Driver
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default DriverDetails;
