import React from "react";
import { Divider } from 'antd'

function NotFoundPage() {
  return (
    <div className="text-center">
      <h2 className='my-4'>404 Not Found</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
      <Divider />
    </div>
  );
}

export default NotFoundPage;
