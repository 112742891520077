import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, Divider } from "antd";
import Papa from "papaparse"
import InventoryList from "./InventoryList";
import Alert from "../Alert";

const ManageInventory = () => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const [uploadAlertMessage, setUploadAlertMessage] = useState("");
  const [uploadAlertClassName, setUploadAlertClassName] = useState("d-none");

  const handleUpdateInventory = async (data) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + accessToken);

    let payload = {
      inventoryList: data,
    };

    const requestOptions = {
      method: "PUT",
      headers: headers,
      credentials: "include",
      body: JSON.stringify(payload),
    };

    // todo: make backend api for bulk upload
    const url = `${process.env.REACT_APP_BACKEND}/v1/containers`;

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      if (data.error) {
        setUploadAlertClassName("alert-danger")
        setUploadAlertMessage(data.message)
      } else {
        setUploadAlertClassName("alert-success");
        setUploadAlertMessage("Upload successfully");
      }
    } catch (err) {
      setUploadAlertClassName("alert-danger");
      setUploadAlertMessage(err);
    }
  }

  const handleUpload = () => {
    setUploading(true);

    if (fileList.length) {
      const file = fileList[0]
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          // post to backend
          console.log(results)
          console.log(results.data)
          handleUpdateInventory(results.data)
        },
        error: function(error) {
          console.error()
        }
      })
    }

    setUploading(false);
  };

  const props = {
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
    maxCount: 1,
    multiple: false,
  };



  return (
    <div className="m-2">
      <h2 className="my-4 text-center">Manage Inventory</h2>
      <Divider />
      <div className="d-flex flex-wrap">
        <div className="mx-4 mb-4 text-center">
          <h6 className="mb-2">Bulk Update</h6>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
          <Button
            type="primary"
            onClick={handleUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{
              marginTop: 16,
            }}
          >
            {uploading ? "Uploading" : "Start Upload"}
          </Button>
          <Alert
            message={uploadAlertMessage}
            className={`mt-3 ${uploadAlertClassName}`}
          />
        </div>
        <div className="mx-4 text-center">
          <h6 className="mb-2 text-center">Add Inventory</h6>
          <Button type="dashed">
            <Link to="/add-inventory" style={{ textDecoration: "none" }}>
              Add Inventory
            </Link>
          </Button>
        </div>
        <div className="mx-4 text-center">
          <h6 className="mb-2 text-center">Manage Prices</h6>
          <Button type="dashed">
            <Link to="/manage-prices" style={{ textDecoration: "none" }}>
              Manage Prices
            </Link>
          </Button>
        </div>
      </div>
      <Divider />
      <InventoryList />
    </div>
  );
};

export default ManageInventory;
