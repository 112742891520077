// Function to copy to clipboard
export const CopyToClipboard = (form, message, fieldName) => {
  if (fieldName.toLowerCase().includes("email")) {
    let email = form.getFieldValue(fieldName);
    if (email !== "") {
      const trimmedText = email.trim();
      navigator.clipboard.writeText(trimmedText);
      message.success("Email copied to clipboard");
    }
  }
  if (fieldName.toLowerCase().includes("phone")) {
    let phone = form.getFieldValue(fieldName);
    if (phone !== "") {
      const trimmedText = phone.trim();
      navigator.clipboard.writeText(trimmedText);
      message.success("Phone number copied to clipboard");
    }
  }
};
