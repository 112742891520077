import { useContext } from "react";
import { Table, Space, Button } from "antd";
import { CITIES } from "../../utils/LocationUtils";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

const InventoryTable = (props) => {
  const { containers, zipcode, hideAction } = props;
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate()

  if (containers.length === 0) {
    return null;
  }


  const columns = [
    {
      title: "City",
      key: "city",
      align: "center",
      render: (container) => <>{CITIES[container.city]}</>,
    },
    {
      title: "Location",
      key: "location",
      align: "center",
      render: (container) => <>{container.locationName}</>,
      hidden: true,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      align: "center",
    },
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
      align: "center",
    },
    {
      title: "Quanity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
      defaultSortOrder: "ascend",
      sorter: {
        compare: (a, b) => a.price - b.price,
      },
      render: (price) => <>$ {price}</>,
    },
    {
      title: "Deliver Fee",
      dataIndex: "deliveryRate",
      key: "deliveryRate",
      align: "center",
      render: (value) => {
        let fee = "$ " + value.toFixed(0);
        if (value === 0) {
          fee = "unknown";
        }
        return <>{fee}</>;
      },
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
      align: "center",
      render: (value) => {
        let distance = value.toFixed(0);
        return <span>{distance} mi</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      align: "center",
      hidden: isLoggedIn && !hideAction ? false : true,
      render: (container) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() =>
              navigate("/add-lead", {
                state: {
                  size: container.size,
                  condition: container.condition,
                  zipcode: zipcode,
                  price: container.price,
                  deliveryFee: container.deliveryRate.toFixed(),
                  distanceInMeter: container.meters,
                  nearbyCity: container.city,
                },
              })
            }
          >
              Create Lead
          </Button>
        </Space>
      ),
    },
  ].filter((item) => !item.hidden);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={containers}
        pagination={{
          position: ["none", "none"],
        }}
        rowKey="locationId"
        scroll={{
          x: 800,
        }}
      />
    </div>
  );
};

export default InventoryTable;
