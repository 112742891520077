import React, { useState } from "react";
import { Modal, Button, message, Divider } from "antd";
import { CopyOutlined, EditOutlined } from "@ant-design/icons";

const containerSizeChart = {
  "20ST": "20ft standard",
  "20HC": "20ft high-cube",
  "40ST": "40ft standard",
  "40HC": "40ft high-cube",
}

const containerConditionChart = {
  "OT": "one-trip",
  "CW": "used",
}

export const QuoteEmailTemplateModal = ({
  visible, onClose, size, condition, leadName, price, deliveryFee, nearbyCity, email
}) => {
  const [pickup, setPickup] = useState(false)
  const formattedPrice = price.toLocaleString("en-US");
  const formattedDeliveryFee = deliveryFee.toLocaleString("en-US");

  // Generate email template content
  const generateEmailTemplate = () => {
    if (!pickup) {
      return `
Hi ${
        leadName.split(" ")[0].charAt(0).toUpperCase() +
        leadName.split(" ")[0].slice(1).toLowerCase()
      },

Thank you for your inquiry on the ${containerConditionChart[condition]} ${
        containerSizeChart[size]
      } container. We have units available in ${nearbyCity}. Please see below for the quote. The total pricing for the ${
        containerConditionChart[condition]
      } ${containerSizeChart[size]} container and delivery is $${(
        price + deliveryFee
      ).toLocaleString(
        "en-US"
      )} ($${formattedPrice} for the container and $${formattedDeliveryFee} delivery fee).

Before you complete the purchase, please confirm the delivery address with our team. To complete the purchase, please click "Complete your purchase" in this email and go through the payment steps. After payment for your order is completed, we will contact you to initiate the delivery process.

If you have any questions, please feel free to contact me. My number is 818-208-0006.

You can also visit our website for more information: www.solidboxcontainer.com

Look forward to speaking with you soon!

Best regards,
James Hong
`;
    } else {
      return `
Hi ${leadName.split(" ")[0]},

Thank you for your inquiry on the ${containerConditionChart[condition]} ${
        containerSizeChart[size]
      } container. We have units available in ${nearbyCity}. Please see below for the quote. The price for the ${
        containerConditionChart[condition]
      } ${containerSizeChart[size]} container is $${formattedPrice}.

To complete the purchase, please click "Complete your purchase" in this email and go through the payment steps. After payment for your order is completed, we will contact you to initiate the pick-up process.

If you have any questions, please feel free to contact me. My number is 818-208-0006.

You can also visit our website for more information: www.solidboxcontainer.com

Look forward to speaking with you soon!

Best regards,
James Hong
`;
    }
  };

  // Function to copy email template to clipboard
  const copyToClipboard = () => {
    const trimmedTemplate = generateEmailTemplate().trim();
    navigator.clipboard.writeText(trimmedTemplate);
    message.success("Email template copied to clipboard");
  };

  const copyEmailToClipboard = () => {
    if (email !== "") {
      const trimmedText = email.trim();
      navigator.clipboard.writeText(trimmedText);
      message.success("Email address copied to clipboard");
    }
  }

  // Function to open Shopify create order page in a new window
  const openShopifyCreateOrder = () => {
    window.open(
      "https://admin.shopify.com/store/solidboxcontainer/orders",
      "_blank"
    );
  };

  return (
    <Modal
      title="Quote Email Template"
      open={visible}
      onCancel={onClose}
      footer={[
        <div key="template-footer" className="d-flex flex-column">
          <div className="mb-2">
            {email !== "" && <Button
              key="copy-email"
              onClick={copyEmailToClipboard}
              icon={<CopyOutlined />}
            >
              Copy Email Address
            </Button>}
            <Button
              key="copy"
              onClick={copyToClipboard}
              icon={<CopyOutlined />}
            >
              Copy Text
            </Button>
          </div>
          <div>
            <Button
              key="pickup"
              type="dashed"
              onClick={() => setPickup((prev) => !prev)}
            >
              {!pickup ? "Self pick up" : "Delivery"}
            </Button>
            <Button
              key="shopify"
              type="dashed"
              onClick={openShopifyCreateOrder}
              icon={<EditOutlined />}
            >
              Create Order on Shopify
            </Button>
          </div>
        </div>,
      ]}
      draggable
      centered
    >
      <div style={{ whiteSpace: "pre-line" }}>
        <Divider />
        {generateEmailTemplate()}
        <Divider />
      </div>
    </Modal>
  );
};
