import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { Button, Modal, Tooltip } from "antd";
import Logo from "./../../images/sbc-logo-no-background.png";
import {
  LogoutOutlined,
  UserOutlined,
  DollarOutlined,
  TruckOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import SearchModal from "../Inventory/SearchModal";
import DeliveryRateCalculatorModal from "../Locations/DeliveryRateCalculatorModal";
import QuickQuoteModal from '../Inventory/QuickQuoteModal'

const HeaderMenu = () => {
  const { isLoggedIn, logout } = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [openQuoteModal, setOpenQuoteModal] = useState(false);
  const [openCalculatorModal, setOpenCalculatorModal] = useState(false);

  const showModal = () => {
    setOpen(true)
  };

  const handleOk = () => {
    logout()
    setOpen(false)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <div className="w-100 d-flex justify-content-between align-items-center">
      <div>
        <Link to="/" style={{ textDecoration: "none" }}>
          <img
            src={Logo}
            alt="sbc-logo"
            style={{ maxHeight: "5vh", maxWidth: "20vw" }}
          />
        </Link>
      </div>
      <div>
        {isLoggedIn &&
          <div className="d-flex gap-3 align-items-center">
            <Tooltip title="Inventory Search">
              <Button
                shape="circle"
                icon={<FileSearchOutlined />}
                onClick={() => setOpenSearchModal(true)}
              />
            </Tooltip>
            <Tooltip title="Quick Quote Check">
              <Button
                shape="circle"
                icon={<DollarOutlined />}
                onClick={() => setOpenQuoteModal(true)}
              />
            </Tooltip>
            <Tooltip title="Delivery Estimate">
              <Button
                shape="circle"
                icon={<TruckOutlined />}
                onClick={() => setOpenCalculatorModal(true)}
              />
            </Tooltip>
            <Link to="/profile" style={{ textDecoration: "none" }}>
              <Tooltip title="Profile">
                <Button shape="circle" icon={<UserOutlined />} />
              </Tooltip>
            </Link>
            <Tooltip title="log out">
              <Button
                shape="circle"
                icon={<LogoutOutlined />}
                onClick={showModal}
              />
            </Tooltip>
            <Modal
              title="Are you sure you want to logout?"
              open={open}
              onOk={handleOk}
              onCancel={handleCancel}
              centered={true}
              closeIcon={false}
              okText="Log Out"
              destroyOnClose={true}
            ></Modal>
            <Modal
              open={openQuoteModal}
              onCancel={() => setOpenQuoteModal(false)}
              centered={true}
              closeIcon={true}
              footer={null}
              destroyOnClose={true}
            >
              <QuickQuoteModal />
            </Modal>
            <Modal
              open={openSearchModal}
              onCancel={() => setOpenSearchModal(false)}
              centered={true}
              closeIcon={true}
              footer={null}
              destroyOnClose={true}
            >
              <SearchModal />
            </Modal>
            <Modal
              open={openCalculatorModal}
              onCancel={() => setOpenCalculatorModal(false)}
              centered={true}
              closeIcon={true}
              footer={null}
              destroyOnClose={true}
            >
              <DeliveryRateCalculatorModal />
            </Modal>
          </div>
        }
      </div>
    </div>
  );
};

export default HeaderMenu;
