import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom"
import AuthContext from "../../context/AuthContext";
import { Divider, Table, Button, Modal, Space, Input } from "antd";
import { CITIES } from "../../utils/LocationUtils";

const Locations = () => {
  const [locations, setLocations] = useState([]);
  const [acted, setActed] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [searchValue, setSearchValue] = useState("")
  const [loading, setLoading] = useState(false);

  const { authTokens, userRole } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;

  useEffect(() => {
    const fetchData = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/locations?search_value=${searchValue}`;

      setLoading(true);

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        setLocations(data);
        setLoading(false)
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [accessToken, acted, searchValue]);

  const showModal = (location) => {
    setSelectedLocation(location)
    setOpen(true);
  };

  const handleOk = () => {
    handleDelete(selectedLocation.id);
    setSelectedLocation("")
    setOpen(false);
  };

  const handleCancel = () => {
    setSelectedLocation("")
    setOpen(false);
  };

  const handleDelete = (id) => {
    const deleteLocation = async (id) => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      let requestOptions = {
        method: "DELETE",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/locations/${id}`;

      try {
        await fetch(url, requestOptions)
        // alert success
        console.log("deleted")
        setActed(prev => !prev)
      } catch(err) {
        // alert failure
        console.log(err)
      }
    }

    deleteLocation(id)
  }

  const columns = [
    {
      title: "Location ID",
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      align: "center",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.city.localeCompare(b.city),
      render: (city) => CITIES[city],
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Zipcode",
      dataIndex: "zipcode",
      key: "zipcode",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      align: "center",
      hidden: userRole === "super" ? false : true,
      render: (location) => (
        <Space size="middle">
          <Button type="primary">
            <Link
              to={`/locations/${location.id}`}
              style={{ textDecoration: "none" }}
            >
              View
            </Link>
          </Button>
          <Button type="primary" onClick={() => showModal(location)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ].filter((item) => !item.hidden);

  return (
    <div className="m-2">
      <h2 className="text-center my-4">Our Locations</h2>
      <Divider />
      <div className="d-flex justify-content-between">
        <Input
          placeholder="Search by city, name, or zipcode"
          className="w-50"
          allowClear
          onChange={(event) => setSearchValue(event.target.value)}
        />
        <Button type="primary">
          <Link to="/add-location" style={{ textDecoration: "none" }}>
            Add a new location
          </Link>
        </Button>
      </div>
      <Divider />
      <Table
        columns={columns}
        dataSource={locations}
        scroll={{ x: 800, y: "100%" }}
        rowKey="id"
        loading={loading}
      />
      <Modal
        title={`Are you sure you want to delete this location: ${selectedLocation.name}?`}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        closeIcon={false}
        okText="Yes"
        destroyOnClose={true}
      ></Modal>
    </div>
  );
};

export default Locations;
