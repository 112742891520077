import { useState, useContext, useEffect } from "react";
import { useParams, useNavigate, useLocation} from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Divider, Spin, Modal, message} from "antd"
import {
  CONDITION_OPTIONS,
  SIZE_OPTIONS,
  LEADSTATUS_OPTIONS,
  CHANNEL_OPTIONS,
} from "../../utils/ContainerUtils";
import { GetMeters } from "../../utils/LocationUtils";
import AddTodo from "../Todos/AddTodo";
import LeadTodosList from "../Todos/LeadTodosList";
import LeadTransfer from "./LeadTransfer";
import { QuoteEmailTemplateModal } from "./QuoteEmailTemplateModal";
import { CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "../../utils/FormUtils";

const LeadDetails = () => {
  const { authTokens, user } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;
  const [form] = Form.useForm();
  const { id } = useParams();
  const { TextArea } = Input;
  const navigate = useNavigate();
  const location = useLocation();

  const [leadName, setLeadName] = useState("");
  const [leadId, setLeadId] = useState(0);
  const [leadUserId, setLeadUserId] = useState(0);
  const [lastUpdatedAt, setLastUpdatedAt] = useState();
  const [leadStatus, setLeadStauts] = useState();

  const [allowEdit, setAllowEdit] = useState(false);
  const [updated, setUpdated] = useState(false);

  const [loading, setLoading] = useState(true);

  const [addTodoModalVisible, setAddTodoModalVisible] = useState(false);
  const [refreshTodos, setRefreshTodos] = useState(false);
  const [emailModalVisible, setEmailModalVisible] = useState(false);

  // Handle button click to open email template modal
  const handleGenerateEmail = () => {
    setEmailModalVisible(true);
  };

  const handleTodoRefresh = () => {
    // Toggle the state variable to trigger the refresh of the todo list
    setRefreshTodos((prevState) => !prevState);
  };
  // fetch lead details
  useEffect(() => {
    const fetchData = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/leads/${id}`;

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        if (data.error) {
          console.log(data.message);
          // setError(error);
        } else {
          setLeadName(data.leadName);
          setLeadId(data.leadId);
          setLastUpdatedAt(data.updatedAt);
          setLeadUserId(data.userId);
          setLeadStauts(data.leadStatus);
          form.setFieldsValue({
            leadName: data.leadName,
            leadEmail: data.leadEmail,
            leadPhone: data.leadPhone,
            zipcode: data.destinationZipcode,
            size: data.size,
            condition: data.condition,
            price: data.price,
            deliveryFee: data.deliveryPrice,
            leadStatus: data.leadStatus,
            channel: data.channel,
            nearbyCity: data.nearbyCity,
            distance: Math.floor(data.distanceInMile),
            notes: data.notes,
            account: data.accountName,
            createdAt: new Date(data.createdAt).toLocaleDateString("en-US"),
          });
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [accessToken, id, form, updated]);

  const handleSubmit = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + accessToken);

    let payload = {
      leadId: leadId,
      leadName: form.getFieldValue("leadName").trim(),
      leadEmail:
        form.getFieldValue("leadEmail") !== ""
          ? form.getFieldValue("leadEmail").trim()
          : null,
      leadPhone:
        form.getFieldValue("leadPhone") !== ""
          ? form.getFieldValue("leadPhone").trim()
          : null,
      size: form.getFieldValue("size"),
      condition: form.getFieldValue("condition"),
      destinationZipcode:
        form.getFieldValue("zipcode") !== ""
          ? form.getFieldValue("zipcode")
          : null,
      price:
        form.getFieldValue("price") !== ""
          ? parseInt(form.getFieldValue("price"))
          : null,
      deliveryPrice:
        form.getFieldValue("deliveryFee") !== ""
          ? parseInt(form.getFieldValue("deliveryFee"))
          : null,
      channel:
        form.getFieldValue("channel") !== ""
          ? form.getFieldValue("channel")
          : null,
      nearbyCity:
        form.getFieldValue("nearbyCity") !== ""
          ? form.getFieldValue("nearbyCity").trim()
          : null,
      DistanceInMeter:
        form.getFieldValue("distance") !== ""
          ? GetMeters(form.getFieldValue("distance"))
          : null,
      notes:
        form.getFieldValue("notes") !== ""
          ? form.getFieldValue("notes").trim()
          : null,
    };

    const requestOptions = {
      method: "PATCH",
      headers: headers,
      credentials: "include",
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/leads/${id}`;

    try {
      let response = await fetch(url, requestOptions);
      if (response.ok) {
        message.success("Lead updated");
        setUpdated((prev) => !prev);
      } else {
        message.error("Failed to update the lead");
      }
      // setLoading(false);
    } catch (err) {
      message.error("Error updating lead");
    } finally {
      setAllowEdit(false);
    }
  };

  const handleUpdateStatus = async (val) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + accessToken);

    let payload = {
      leadStatus: val,
    };

    const requestOptions = {
      method: "PATCH",
      headers: headers,
      credentials: "include",
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/leads/status/${id}`;

    try {
      let response = await fetch(url, requestOptions);
      if (response.ok) {
        message.success("Lead status updated");
      } else {
        message.error("Failed to update the lead status");
      }
    } catch (err) {
      message.error("Error updating lead status");
    } finally {
      setLeadStauts(val);
    }
  };

  const handleAddTodoSuccess = () => {
    // Handle success logic if needed
    setAddTodoModalVisible(false); // Close the AddTodo modal
  };

  const from = location.state?.from;
  const handleGoBack = () => {
    if (from) {
      navigate(from);
    } else {
      navigate("/leads");
    }
  };

  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : (
        <div className="m-2">
          <Button onClick={handleGoBack}>
            <ArrowLeftOutlined /> back
          </Button>
          <div className="mb-4 d-flex flex-column align-items-center justify-content-center">
            <div className="text-center mb-2 fs-4 fw-bold">{leadName}</div>
            <Select
              placeholder="Select the lead status"
              options={LEADSTATUS_OPTIONS}
              value={leadStatus}
              onChange={(val) => handleUpdateStatus(val)}
              style={{ width: "130px" }}
            />
          </div>
          <Divider />
          <div className="text-center mb-4">
            <Button onClick={() => setAddTodoModalVisible(true)}>
              Add Task
            </Button>
          </div>
          <LeadTodosList
            leadId={id}
            refreshTodos={refreshTodos}
            setRefreshTodos={setRefreshTodos}
          />
          <Modal
            open={addTodoModalVisible}
            onCancel={() => setAddTodoModalVisible(false)}
            centered={true}
            footer={null}
          >
            <AddTodo
              leadId={id ? id : leadId}
              leadUserId={leadUserId}
              onSuccess={handleAddTodoSuccess}
              onRefresh={handleTodoRefresh}
            />
          </Modal>
          <Divider />

          <div className="mb-4 d-flex flex-wrap align-items-center justify-content-center">
            {lastUpdatedAt && (
              <div className="m-2">
                <em>
                  Last updated at:{" "}
                  {new Date(lastUpdatedAt).toLocaleString("en-US", {
                    hour12: false,
                  })}
                </em>
              </div>
            )}
            {allowEdit && (
              <div className="d-flex justify-content-around">
                <Button className="mx-2" type="primary" onClick={handleSubmit}>
                  Update
                </Button>
                <Button onClick={() => setAllowEdit(false)}>Cancel</Button>
              </div>
            )}
            {!allowEdit && (
              <div className="d-flex justify-content-around">
                <Button className="mx-2" onClick={() => setAllowEdit(true)}>
                  Edit Info
                </Button>
              </div>
            )}
          </div>
          <Form
            form={form}
            name="edit-lead"
            onFinish={handleSubmit}
            disabled={!allowEdit}
          >
            <div className="d-flex flex-wrap justify-content-around">
              <div className="mx-2">
                <Form.Item
                  name="leadName"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the name of the lead.",
                    },
                    {
                      pattern: new RegExp("^[a-zA-Z0-9 '-]*$"),
                      message: "Only alphabet characters are allowed",
                    },
                  ]}
                >
                  <Input placeholder="Lead Name" />
                </Form.Item>

                <Form.Item
                  name="leadEmail"
                  label="Email"
                  rules={[
                    {
                      type: "email",
                      message: "Not a valid email",
                    },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    suffix={
                      form.getFieldValue("leadEmail") !== "" && (
                        <Button
                          key="copy"
                          size="small"
                          onClick={() => CopyToClipboard(form, message, "leadEmail")}
                          icon={<CopyOutlined />}
                          disabled={false}
                        />
                      )
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="leadPhone"
                  label="Phone Number"
                  rules={[
                    {
                      max: 10,
                      message: "Phone number can't be more than 10 characters",
                    },
                    {
                      pattern: new RegExp("^[0-9]+$"),
                      message: "Only numbers are allowed",
                    },
                  ]}
                >
                  <Input
                    placeholder="Phone Number"
                    suffix={
                      form.getFieldValue("leadPhone") !== "" && (
                        <Button
                          key="copy"
                          size="small"
                          onClick={() => CopyToClipboard(form, message, "leadPhone")}
                          icon={<CopyOutlined />}
                          disabled={false}
                        />
                      )
                    }
                  />
                </Form.Item>

                <Form.Item name="channel" label="Channel">
                  <Select
                    placeholder="Select the lead channel"
                    options={CHANNEL_OPTIONS}
                  />
                </Form.Item>

                <Form.Item name="account" label="Account">
                  <Input placeholder="Account Name" disabled={true} />
                </Form.Item>

                <Form.Item name="createdAt" label="Create Date">
                  <Input placeholder="Created At" disabled />
                </Form.Item>
              </div>

              <div className="mx-2">
                <Form.Item
                  name="size"
                  label="Size"
                  rules={[
                    {
                      required: true,
                      message: "Please select a container size.",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select the container size"
                    allowClear={true}
                    options={SIZE_OPTIONS}
                  />
                </Form.Item>

                <Form.Item
                  name="condition"
                  label="Condition"
                  rules={[
                    {
                      required: true,
                      message: "Please select a container condition.",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select the container condition"
                    allowClear={true}
                    options={CONDITION_OPTIONS}
                  />
                </Form.Item>

                <Form.Item
                  name="zipcode"
                  label="Zipcode"
                  rules={[
                    {
                      pattern: new RegExp("^[0-9]{5}"),
                      message: "Not a valid zipcode.",
                    },
                  ]}
                >
                  <Input placeholder="Zipcode" maxLength={5} allowClear />
                </Form.Item>

                <Form.Item name="nearbyCity" label="Nearby City">
                  <Input placeholder="Nearby City" />
                </Form.Item>

                <Form.Item name="distance" label="Distance">
                  <Input
                    placeholder="Distance"
                    suffix="miles"
                    type="number"
                    min="0"
                    step="any"
                    allowClear
                  />
                </Form.Item>
              </div>

              <div className="mx-2">
                <Form.Item name="price" label="Price">
                  <Input
                    placeholder="Price"
                    prefix="$"
                    type="number"
                    min="1"
                    allowClear
                  />
                </Form.Item>

                <Form.Item name="deliveryFee" label="Delivery Fee">
                  <Input
                    placeholder="Delivery Fee"
                    prefix="$"
                    type="number"
                    min="0"
                    allowClear
                  />
                </Form.Item>

                <Form.Item
                  name="notes"
                  label="Notes"
                  rules={[
                    {
                      max: 200,
                      message: "Can't exceed 200 characters",
                    },
                  ]}
                >
                  <TextArea allowClear autoSize />
                </Form.Item>

                <div className="d-flex justify-content-end">
                  <Button disabled={false} onClick={handleGenerateEmail}>
                    Generate Quote Email Template
                  </Button>
                  <QuoteEmailTemplateModal
                    visible={emailModalVisible}
                    onClose={() => setEmailModalVisible(false)}
                    size={form.getFieldValue("size")}
                    condition={form.getFieldValue("condition")}
                    leadName={form.getFieldValue("leadName")}
                    price={form.getFieldValue("price")}
                    deliveryFee={form.getFieldValue("deliveryFee")}
                    nearbyCity={form.getFieldValue("nearbyCity")}
                    email={form.getFieldValue("leadEmail")}
                  />
                </div>
              </div>
            </div>
          </Form>
          <Divider />
          {user.userID === leadUserId && <LeadTransfer leadId={id} />}
        </div>
      )}
    </div>
  );
};


export default LeadDetails;
