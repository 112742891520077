import { useState, useEffect } from "react";

// Constants for all supported locations
export const CITIES = {
  atlanta: "Atlanta, GA",
  baltimore: "Baltimore, MD",
  boston: "Boston, MA",
  charleston: "Charleston, SC",
  chicago: "Chicago, IL",
  cincinnati: "Cincinnati, OH",
  cleveland: "Cleveland, OH",
  columbus: "Columbus, OH",
  dallas: "Dallas, TX",
  denver: "Denver, CO",
  detroit: "Detroit, MI",
  "el paso": "El Paso, TX",
  houston: "Houston, TX",
  "kansas city": "Kansas City, MO",
  "los angeles": "Los Angeles, CA",
  memphis: "Memphis, TN",
  miami: "Miami, FL",
  mobile: "Mobile, AL",
  minneapolis: "Minneapolis, MN",
  nashville: "Nashville, TN",
  "new orleans": "New Orleans, LA",
  "new york": "New York, NJ",
  norfolk: "Norfolk, VA",
  savannah: "Savannah, GA",
  "salt lake city": "Salt Lake City, UT",
  "st louis": "St Louis, MO",
  tampa: "Tampa, FL",
  wilmington: "Wilmington, NC",
  charlotte: "Charlotte, NC",
  louisville: "Louisville, KY",
  jacksonville: "Jacksonville, FL",
  seattle: "Seattle",
};

// convert meters to miles
export function GetMiles(meters) {
  return meters / 1609.344;
}

// convert miles to meters
export function GetMeters(miles) {
  return Math.round(miles * 1609.344);
}

export const useFetchCities = () => {
  const [cities, setCities] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  useEffect(() => {
    const fetchCities = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/locations/cities`;

      try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        setCities(data);
        setCityOptions(
          data.map((city, index) => ({
            value: index,
            label: CITIES[city.city],
          }))
        );
      } catch (err) {
        console.error(err);
      }
    };

    fetchCities();
  }, []);

  return { cities, cityOptions };
};
