import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Divider, Table, Button, Input, Radio } from "antd";
import { CITIES } from "../../utils/LocationUtils";
import { ArrowLeftOutlined } from "@ant-design/icons";

const InventoryCityList = () => {
  const [containers, setContainers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [condition, setCondition] = useState("CW");

  const { city } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: headers,
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/containers/city?city=${city}&condition=${condition}&search_value=${searchValue}`;

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        if (data.error) {
          console.log(data.message);
        } else {
          setContainers(data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [searchValue, condition, city]);

  const columns = [
    {
      title: "City",
      key: "city",
      fixed: "left",
      align: "center",
      render: (container) => <>{CITIES[container.city]}</>,
    },
    {
      title: "Location",
      key: "location",
      align: "center",
      render: (container) => <>{container.locationName}</>,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      align: "center",
    },
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
      align: "center",
    },
    {
      title: "Quanity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
      render: (value) => <span>$ {value}</span>,
    },
  ];

  return (
    <div className="m-2">
      <Button>
        <Link to="/inventory" style={{ textDecoration: "none" }}>
          <ArrowLeftOutlined /> back
        </Link>
      </Button>
      <h2 className="text-center mb-4">{CITIES[city]}</h2>
      <Divider />
      <Input
        placeholder="Search"
        className="w-50 mb-4"
        allowClear
        onChange={(event) => setSearchValue(event.target.value)}
      />
      <div className="text-center">
        <Radio.Group
          defaultValue="CW"
          buttonStyle="solid"
          size="large"
          onChange={(event) => setCondition(event.target.value)}
        >
          <Radio.Button value="CW">Used</Radio.Button>
          <Radio.Button value="OT">New</Radio.Button>
        </Radio.Group>
      </div>
      <Table
        columns={columns}
        dataSource={containers}
        className="mt-3"
        scroll={{ x: "scroll" }}
        rowKey="id"
      />
    </div>
  );
};

export default InventoryCityList;
