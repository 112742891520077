import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const PrivateRoutes = () => {
  let { isLoggedIn } = useContext(AuthContext);
  return isLoggedIn ? (
    <>
    {/* <div className="container"> */}
      {/* <NavBar /> */}
      <Outlet />
    {/* </div> */}
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
