import { useState, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { Divider, Input, Radio, Tabs } from "antd";
import './Inventory.css'
import { SearchOutlined } from "@ant-design/icons";
import PriceSummaryTable from "./PriceSummaryTable";
import InventorySummaryTable from "./InventorySummaryTable";

const InventorySummary = () => {
  const { isLoggedIn } = useContext(AuthContext);

  const [searchValue, setSearchValue] = useState("");
  const [condition, setCondition] = useState("CW");

  return (
    <div className="m-2">
      <h2 className="text-center my-4">Current Inventory</h2>
      <Divider />
      <div className="d-flex flex-wrap justify-content-between align-items-center">
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search by city"
          className="w-50"
          allowClear
          onChange={(event) => setSearchValue(event.target.value)}
        />
      </div>
      <div className="mt-2 text-secondary">
        <em>
          * Limited inventory available for special sized units (20HC, 40ST,
          40HCDD)
        </em>
      </div>
      <Divider />
      <Tabs
        defaultActiveKey="inventory"
        type="card"
        size="large"
        items={[
          {
            key: "inventory",
            label: "Inventory",
            children: (
              <InventorySummaryTable
                searchValue={searchValue}
                condition={condition}
                isLoggedIn={isLoggedIn}
              />
            ),
          },
          {
            key: "price",
            label: "Price",
            children: (
              <PriceSummaryTable
                searchValue={searchValue}
                condition={condition}
                isLoggedIn={isLoggedIn}
              />
            ),
          },
        ]}
        tabBarExtraContent={
          <Radio.Group
            defaultValue="CW"
            buttonStyle="solid"
            size="middle"
            onChange={(event) => setCondition(event.target.value)}
          >
            <Radio.Button value="CW">Used</Radio.Button>
            <Radio.Button value="OT">New</Radio.Button>
          </Radio.Group>
        }
      />
    </div>
  );
};

export default InventorySummary;
