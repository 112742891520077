import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const AdminRoutes = () => {
  let { isLoggedIn, userRole } = useContext(AuthContext);
  return isLoggedIn && userRole !== "user" ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default AdminRoutes;
