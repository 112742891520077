import { useEffect, useState, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { Divider, Table, Button, Space, Tooltip } from 'antd'
import { Link } from "react-router-dom";

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const { authTokens, userRole, user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + authTokens.accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/admin/users`;

      setLoading(true);

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        setUsers(data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }

    fetchUsers()
  }, [authTokens])

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      fixed: "left",
      width: "auto",
      ellipsis: true,
      render: (email) => (
        <Tooltip placement="topLeft" title={email}>
          {email}
        </Tooltip>
      )
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      align: "center",
      width: "auto",
    },
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "firstname",
      align: "center",
      width: "auto",
      render: (val) => val["String"],
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "lastname",
      align: "center",
      width: "auto",
      render: (val) => val["String"],
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      align: "center",
      width: "auto",
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      // fixed: "right",
      width: "auto",
      align: "center",
      render: (record) => {
        if (user.email !== record.email) {
          return (
            <Space size="middle">
              <Button type="primary">
                <Link
                  to={`/admin/users/${record.id}`}
                  style={{ textDecoration: "none" }}
                >
                  Edit
                </Link>
              </Button>
            </Space>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  return (
    <div className="m-2">
      <h2 className="my-4 text-center">Users</h2>
      <Divider />
      {userRole !== "super" ? (
        <div>No access</div>
      ) : (
        <div>
          <Table
            columns={columns}
            dataSource={users}
            scroll={{ x: 800, y: "100%" }}
            rowKey="id"
            bordered
            loading={loading}
          />
        </div>
      )}
    </div>
  );
};

export default UsersList;
