import { useState, useContext, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import {
  Button,
  Form,
  Input,
  Divider,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

const EditInventory = () => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;

  const [city, setCity] = useState(0);
  const [locationName, setLocationName] = useState("");
  const [size, setSize] = useState("");
  const [condition, setCondition] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(0);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/containers/${id}`;

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        // todo, add error handling
        setCity(data.city)
        setLocationName(data.name)
        setSize(data.size)
        setCondition(data.condition)
        setQuantity(data.quantity)
        setPrice(data.price)

        form.setFieldsValue({
          city: data.city,
          location: data.name,
          size: data.size,
          condition: data.condition,
          quantity: data.quantity,
          price: data.price
        });
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [accessToken, id, form]);

  const handleSubmit = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + accessToken);

    let payload = {
      id: parseInt(id),
      quantity: parseInt(quantity),
      price: parseInt(price),
    };

    const requestOptions = {
      method: "PUT",
      headers: headers,
      credentials: "include",
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/containers/${id}`;

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      if (data.error) {
        console.log(data.message);
        // setError(error);
      } else {
        // todo: success message
        console.log("update successfully");
        // todo: wait a second to navigate
        navigate("/manage-inventory", { replace: true})
      }
      // setLoading(false);
    } catch (err) {
      console.log(err);
      // setError(error);
      // setLoading(false);
    }
  }

  return (
    <div className="m-2">
      <Button>
        <Link to="/manage-inventory" style={{ textDecoration: "none" }}>
          <ArrowLeftOutlined /> back
        </Link>
      </Button>
      <h2 className="mt-3 text-center">Edit Inventory</h2>
      <Divider />

      <Form form={form} name="edit-inventory" onFinish={handleSubmit}>
        <Form.Item name="city" label="City">
          <Input value={city} disabled />
        </Form.Item>

        <Form.Item name="location" label="Location">
          <Input value={locationName} disabled />
        </Form.Item>

        <Form.Item name="size" label="Size">
          <Input value={size} disabled />
        </Form.Item>

        <Form.Item name="condition" label="Condition">
          <Input value={condition} disabled />
        </Form.Item>

        <Form.Item
          name="quantity"
          label="Quantity"
          rules={[
            {
              required: true,
              message: "Please enter a quantity.",
            },
          ]}
        >
          <Input
            placeholder="Quantity"
            type="number"
            min="0"
            value={quantity}
            onChange={(event) => {
              setQuantity(event.target.value);
            }}
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="price"
          label="Price"
          rules={[
            {
              required: true,
              message: "Please enter a price.",
            },
          ]}
        >
          <Input
            placeholder="Price"
            prefix="$"
            type="number"
            min="1"
            value={price}
            onChange={(event) => {
              setPrice(event.target.value);
            }}
            allowClear
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!city}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditInventory;
