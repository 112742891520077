import { QuickLinksWidget } from './QuickLinksWidget';
import { ResourcesWidget } from './ResourcesWidget';
import Banner from './../../images/sbc-banner-2.jpg'

const Home = () => {

  return (
    <>
      <div className="text-center">
        <h2 className="m-4 roboto-font">
          Welcome to Solidbox Container's Portal
        </h2>
        <div className="row mx-3">
          <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
            <QuickLinksWidget />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
            <ResourcesWidget />
          </div>
        </div>
        <div>
          <img
            className="px-4 mb-4 w-100 object-fit-contain"
            src={Banner}
            alt="sbc-banner"
          />
        </div>
      </div>
    </>
  );
}

export default Home
