import { useState, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { LockTwoTone, MailTwoTone, IdcardTwoTone, SmileTwoTone } from "@ant-design/icons";
import { Button, Form, Input, Divider } from "antd";
import Alert from "../Alert";
import { Link, useNavigate } from "react-router-dom";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [inviteCode, setInviteCode] = useState("");
  const navigate = useNavigate()

  const { signup, alertMessage, alertClassName, setAlertClassName, isLoggedIn } =
    useContext(AuthContext);

  const onFinish = () => {
    signup(email.toLowerCase(), username.toLowerCase(), password, inviteCode);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (isLoggedIn) {
    navigate("/tasks");
  }

  return (
    <div className="m-2">
      <h2 className="text-center my-4">Sign Up</h2>
      <Divider />
      <div className="d-flex flex-column justify-content-center align-items-center">
        <p className="text-center fw-bold">
          Welcome to the Solidbox Container's Portal! <br />
          Please contact james.hong@solidboxcontainer.com <br />
          to get an invite code.
        </p>
        <Form
          name="normal_signup"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="mt-4"
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "Not a valid email",
              },
              {
                required: true,
                message: "Please enter your email",
              },
            ]}
          >
            <Input
              prefix={<MailTwoTone className="site-form-item-icon" />}
              placeholder="Email"
              onChange={(event) => {
                setEmail(event.target.value);
                setAlertClassName("d-none");
              }}
            />
          </Form.Item>

          <Form.Item
            name="username"
            rules={[
              { required: true, message: "Please enter a username" },
              {
                min: 3,
                message: "Username must be minimum 3 characters",
              },
              {
                pattern: new RegExp("^[a-zA-Z0-9]+$"),
                message: "Only alphabet characters and numbers are allowed",
              },
            ]}
          >
            <Input
              prefix={<IdcardTwoTone className="site-form-item-icon" />}
              placeholder="Username"
              onChange={(event) => {
                setUsername(event.target.value.trim());
                setAlertClassName("d-none");
              }}
            />
          </Form.Item>

          <Form.Item
            name="invitecode"
            rules={[
              { required: true, message: "Please enter the invite code" },
            ]}
          >
            <Input
              prefix={<SmileTwoTone className="site-form-item-icon" />}
              placeholder="Invite Code"
              onChange={(event) => {
                setInviteCode(event.target.value);
                setAlertClassName("d-none");
              }}
            />
          </Form.Item>

          <Form.Item
            name="password"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please enter a password",
              },
              {
                min: 6,
                message: "Password must be minimum 6 characters",
              },
              {
                pattern: new RegExp("^[a-zA-Z0-9!@#$%^&]*$"),
                message:
                  "Only the following special characters are allowed: !@#$%^&",
              },
            ]}
          >
            <Input.Password
              prefix={<LockTwoTone className="site-form-item-icon" />}
              placeholder="Password"
              onChange={(event) => {
                setPassword(event.target.value);
                setAlertClassName("d-none");
              }}
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockTwoTone className="site-form-item-icon" />}
              placeholder="Confirm password"
            />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 32 }}>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Sign Up
            </Button>
          </Form.Item>
          <Form.Item>
            <Alert message={alertMessage} className={alertClassName} />
          </Form.Item>
        </Form>
      </div>
      <div className="text-center mt-4">
        <em>
          Already registered?{" "}
          <Link to="/login" onClick={() => setAlertClassName("d-none")}>
            Log in to the Portal
          </Link>
        </em>
      </div>
    </div>
  );
};

export default Signup;
