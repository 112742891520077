import { Link } from "react-router-dom";
import { RightCircleTwoTone } from "@ant-design/icons";
import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  YoutubeFilled,
} from "@ant-design/icons";

export const ResourcesWidget = () => {
  return (
    <div className="h-100 border p-4 bg-light">
      <h5 className="m-2 mb-3 text-start">Resources </h5>
      <div className="m-4 d-flex flex-wrap">
        <div className="col-lg-6 col-sm-12 mb-3">
          <h6 className="text-start mb-3">
            <RightCircleTwoTone className="me-2" />
            <Link
              to="https://solidboxcontainer.com/pages/warranty"
              target="_blank"
            >
              Warranty Info
            </Link>
          </h6>
          <h6 className="text-start mb-3">
            <RightCircleTwoTone className="me-2" />
            <Link
              to="https://solidboxcontainer.com/pages/one-trip-containers"
              target="_blank"
            >
              One trip units
            </Link>
          </h6>
          <h6 className="text-start mb-3">
            <RightCircleTwoTone className="me-2" />
            <Link
              to="https://solidboxcontainer.com/pages/used-containers"
              target="_blank"
            >
              Used units
            </Link>
          </h6>
          <h6 className="text-start mb-3">
            <RightCircleTwoTone className="me-2" />
            <Link
              to="https://solidboxcontainer.com/pages/faqs"
              target="_blank"
            >
              Common questions
            </Link>
          </h6>
          <h6 className="text-start mb-3">
            <RightCircleTwoTone className="me-2" />
            <Link
              to="/delivery-details"
            >
              Delivery Details
            </Link>
          </h6>
        </div>

        <div className="col-lg-6 col-sm-12 mb-3">
          <h6 className="text-start mb-3">
            <FacebookFilled className="me-2" />
            <Link
              to="https://www.facebook.com/solidboxcontainer"
              target="_blank"
            >
              Facebook Page
            </Link>
          </h6>
          <h6 className="text-start mb-3">
            <InstagramFilled className="me-2" />
            <Link
              to="https://www.instagram.com/solidboxcontainer/"
              target="_blank"
            >
              Instagram{" "}
            </Link>
          </h6>
          <h6 className="text-start mb-3">
            <LinkedinFilled className="me-2" />
            <Link
              to="https://www.linkedin.com/company/solidbox-container"
              target="_blank"
            >
              LinkedIn
            </Link>
          </h6>
          <h6 className="text-start mb-3">
            <YoutubeFilled className="me-2" />
            <Link
              to="https://www.youtube.com/channel/UCyVxE4DImbJ0GHMJiQOP8Iw"
              target="_blank"
            >
              Youtube
            </Link>
          </h6>
        </div>
      </div>
    </div>
  );
};
