import { Link } from "react-router-dom";
import { Button, Divider, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { CopyOutlined } from "@ant-design/icons";
import Size20ST from "../../images/size-20-st.jpeg";
import Size40HC from "../../images/size-40-hc.jpeg";

const DeliveryDetails = () => {
  const copyToClipboard = () => {
    const textToCopy =
`Doors Forward - The doors will face towards the front of the truck and will be the last thing off the trailer as it is delivered.

Doors to the Rear - The doors will face away from the truck and will be the first thing off the trailer as it is delivered.`;
    navigator.clipboard.writeText(textToCopy);
    message.success("Doors orientation info copied to clipboard");
  };

  return (
    <div className="m-2">
      <Button>
        <Link to="/" style={{ textDecoration: "none" }}>
          <ArrowLeftOutlined /> back
        </Link>
      </Button>
      <h2 className="text-center">Delivery Details</h2>
      <Divider />
      <div className="m-2">
        <h6 className="mb-4 fw-bold">Doors Orientation</h6>
        <p>
          <span className="text-primary">Doors Forward</span> - The doors will
          face towards the front of the truck and will be the last thing off the
          trailer as it is delivered.
        </p>
        <p>
          <span className="text-primary">Doors to the Rear</span> - The doors
          will face away from the truck and will be the first thing off the
          trailer as it is delivered.
        </p>
        <Button
          size="small"
          key="copy"
          onClick={copyToClipboard}
          icon={<CopyOutlined />}
        >
          Copy Text
        </Button>
      </div>
      <Divider />
      <div className="m-2">
        <h6 className="mb-4 fw-bold">Dimensions</h6>
        <div className="row">
          <img
            className="px-4 mb-4 col-lg-6 col-md-6 col-sm-12 object-fit-contain"
            src={Size20ST}
            alt="20ft-container-dimensions"
          />
          <img
            className="px-4 mb-4 col-lg-6 col-md-6 col-sm-12 object-fit-contain"
            src={Size40HC}
            alt="40ft-container-dimensions"
          />
        </div>
      </div>
      <Divider />
    </div>
  );
}

export default DeliveryDetails
