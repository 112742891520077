import { useState, useContext } from 'react'
import { useNavigate, Link } from "react-router-dom";
import { Button, Form, Input, Divider } from "antd";
import AuthContext from "../../context/AuthContext";
import { ArrowLeftOutlined } from "@ant-design/icons";

const AddLocation = () => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens.accessToken

  const [zipcode, setZipcode] = useState("")
  const [name, setName] = useState("")
  const [city, setCity] = useState("")

  const [form] = Form.useForm()
  const navigate = useNavigate()

  const handleSubmit = () => {

    const addLocation = async () => {
      const headers = new Headers()
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      let payload = {
        city: city.toLowerCase().trim(),
        name: name.trim(),
        zipcode: zipcode,
      }

      let requestOptions = {
        method: "POST",
        headers: headers,
        credentials: "include",
        body: JSON.stringify(payload),
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/locations`

      try {
        const res = await fetch(url, requestOptions)
        const data = await res.json()
        if (data.error) {
          console.log(data.message)
        } else {
          // show success message then redirect

          // redirect to page
          navigate("/locations", { replace: true });
        }
      } catch(error) {
        // do sth with the error
        // setAlertClassName("alert-danger");
        // setAlertMessage(error);
      }
    }

    addLocation()
  }

  return (
    <div className="m-2">
      <Button>
        <Link to="/locations" style={{ textDecoration: "none" }}>
          <ArrowLeftOutlined /> back
        </Link>
      </Button>
      <h2 className="mb-4 text-center">Add Location</h2>
      <Divider />
      <Form form={form} name="add-location" onFinish={handleSubmit}>
        <Form.Item
          name="city"
          rules={[
            {
              required: true,
              message: "Please enter a city.",
            },
          ]}
        >
          <Input
            placeholder="Location City"
            onChange={(event) => {
              setCity(event.target.value);
            }}
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter the name of the location.",
            },
          ]}
        >
          <Input
            placeholder="Location Name"
            onChange={(event) => {
              setName(event.target.value);
            }}
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="zipcode"
          rules={[
            {
              required: true,
              message: "Please enter a zipcode.",
            },
            {
              pattern: new RegExp("^[0-9]{5}"),
              message: "Not a valid zipcode.",
            },
          ]}
        >
          <Input
            placeholder="Location Zipcode"
            maxLength={5}
            onChange={(event) => {
              setZipcode(event.target.value);
            }}
            allowClear
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default AddLocation
