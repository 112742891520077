import { useState, useContext, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Button, Space, Input, Divider, Select, Modal } from "antd";
import AuthContext from "../../context/AuthContext";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Alert from "../Alert";

const AdminEditUser = () => {
  const { authTokens } = useContext(AuthContext);
  const { id } = useParams();
  const [userData, setUserData] = useState()
  const [ newPassword, setNewPassword ] = useState("")
  const [ pwEditClassName, setPwEditClassName ] = useState("d-none")
  const [ accessEditClassName, setAccessEditClassName ] = useState("d-none")
  const [ selectedRole, setSelectedRole ] = useState()
  const [ open, setOpen ] = useState(false)

  const [pwAlertMessage, setPwAlertMessage] = useState("");
  const [pwAlertClassName, setPwAlertClassName] = useState("d-none");

  const [roleAlertMessage, setRoleAlertMessage] = useState("");
  const [roleAlertClassName, setRoleAlertClassName] = useState("d-none");

  const [deleteAlertMessage, setDeleteAlertMessage] = useState("");
  const [deleteAlertClassName, setDeleteAlertClassName] = useState("d-none");

  const navigate = useNavigate();

  const userRoles = {
    "user": "User",
    "admin": "Admin",
    "super": "Super Admin"
  }

  useEffect(() => {
    const fetchUser = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + authTokens.accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/admin/users/${id}`;

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        setUserData(data);
        setSelectedRole(data.role)
      } catch (err) {
        console.log(err);
      }
    }

    fetchUser()
  }, [authTokens, id])

  const handleResetPassword = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + authTokens.accessToken);

    let payload = {
      email: userData.email,
      newPassword: newPassword,
    };

    const requestOptions = {
      method: "PUT",
      headers: headers,
      credentials: "include",
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/admin/users/reset_pw`;

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      if (data.error) {
        setPwAlertClassName("alert-danger");
        setPwAlertMessage(data.error);
      } else {
        setPwAlertClassName("alert-success");
        setPwAlertMessage("Password reset successfully");
      }
    } catch (err) {
      setPwAlertClassName("alert-danger");
      setPwAlertMessage(err);
    }
  }

  const handleRoleSelected = (ele) => {
    setSelectedRole(ele.value);
  };

  const handleUpdateRole = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + authTokens.accessToken);

    let payload = {
      email: userData.email,
      role: selectedRole,
    };

    const requestOptions = {
      method: "PUT",
      headers: headers,
      credentials: "include",
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/admin/users/access`;

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      if (data.error) {
        setRoleAlertClassName("alert-danger");
        setRoleAlertMessage(data.error);
      } else {
        setRoleAlertClassName("alert-success");
        setRoleAlertMessage("User role updated");
      }
    } catch (err) {
      setRoleAlertClassName("alert-danger");
      setRoleAlertMessage(err);
    }
  }


  const handleDeleteUser = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + authTokens.accessToken);

    let payload = {
      email: userData.email,
    };

    const requestOptions = {
      method: "PUT",
      headers: headers,
      credentials: "include",
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/admin/users/delete`;

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      if (data.error) {
        setDeleteAlertClassName("alert-danger");
        setDeleteAlertMessage(data.error);
      } else {
        navigate("/admin/users", { replace: true });
      }
    } catch (err) {
      setDeleteAlertClassName("alert-danger");
      setDeleteAlertMessage(err);
    }
  }

  return (
    <div className="m-2">
      <Button>
        <Link to="/admin/users" style={{ textDecoration: "none" }}>
          <ArrowLeftOutlined /> back
        </Link>
      </Button>
      <h3 className="text-center mt-3">Edit User</h3>
      <Divider />
      <h6 className="mt-3 ms-2 text-decoration-underline">User information</h6>
      {userData ? (
        <div className="ms-2 w-50">
          <div>
            Email: &nbsp;
            <span className="fw-bold">{userData.email}</span>
          </div>
          <div>
            Username: &nbsp;
            <span className="fw-bold">{userData.username}</span>
          </div>
          <div>
            First Name: &nbsp;
            <span className="fw-bold">{userData.firstname["String"]}</span>
          </div>
          <div>
            Last Name: &nbsp;
            <span className="fw-bold">{userData.lastname["String"]}</span>
          </div>
          <div>
            Role: &nbsp;
            <span className="fw-bold">{userRoles[userData.role]}</span>
          </div>
        </div>
      ) : null}

      <Divider />
      {userData && userData.email !== "jameshong177@gmail.com" && (
        <div>
          <Button onClick={() => setPwEditClassName("")}>Reset Password</Button>
          <div className={`${pwEditClassName}`} style={{ maxWidth: "50%" }}>
            <Input.Password
              className="my-2"
              placeholder="input password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <Space>
              <Button
                disabled={newPassword.length < 6}
                onClick={handleResetPassword}
              >
                Submit
              </Button>
              <Button
                type="primary"
                onClick={() => setPwEditClassName("d-none")}
              >
                Cancel
              </Button>
            </Space>
            <Alert message={pwAlertMessage} className={`mt-3 ${pwAlertClassName}`} />
          </div>

          <Divider />
          <Button onClick={() => setAccessEditClassName("")}>
            Edit Access
          </Button>
          {userData && (
            <div className={`${accessEditClassName}`}>
              <div>
                <Select
                  labelInValue
                  defaultValue={{
                    value: userData ? userData.role : "user",
                    label: userData
                      ? userRoles[userData.role]
                      : userRoles["user"],
                  }}
                  className="me-2 my-2"
                  onChange={handleRoleSelected}
                  options={[
                    {
                      value: "user",
                      label: userRoles["user"],
                    },
                    {
                      value: "admin",
                      label: userRoles["admin"],
                    },
                    {
                      value: "super",
                      label: userRoles["super"],
                    },
                  ]}
                />
                <Space>
                  <Button
                    onClick={handleUpdateRole}
                    disabled={userData && userData.role === selectedRole}
                  >
                    Submit
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      setAccessEditClassName("d-none");
                    }}
                  >
                    Cancel
                  </Button>
                </Space>
              </div>
              {selectedRole && userData && selectedRole !== userData.role && (
                <div>
                  Setting access role to&nbsp;
                  <span className="fw-bold">{userRoles[selectedRole]}</span>
                </div>
              )}
              <Alert
                message={roleAlertMessage}
                className={`mt-3 ${roleAlertClassName}`}
              />
            </div>
          )}

          <Divider />
          <div>
            <Button type="primary" danger onClick={() => setOpen(true)}>
              Delete User
            </Button>
            <Modal
              title="Are you sure you want to delete this user?"
              open={open}
              onOk={handleDeleteUser}
              onCancel={() => setOpen(false)}
              centered={true}
              closeIcon={false}
              okText="Delete!"
              destroyOnClose={true}
            ></Modal>
          </div>
          <Alert message={deleteAlertMessage} className={`mt-3 ${deleteAlertClassName}`} />
        </div>
      )}
    </div>
  );
}

export default AdminEditUser
