import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Divider, Input, Select } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Alert from "../Alert";
import { useFetchCities } from "../../utils/LocationUtils";
import AuthContext from "../../context/AuthContext";
import InputMask from "react-input-mask";

function AddDriver() {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;

  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [notes, setNotes] = useState("");
  const { cities, cityOptions } = useFetchCities();
  const [selectedCities, setSelectedCities] = useState([]);

  const [addDriverAlertMessage, setAddDriverAlertMessage] = useState("");
  const [addDriverAlertClassName, setAddDriverAlertClassName] = useState("d-none");

  // const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { TextArea } = Input;

  const handleSubmit = () => {
    const addDriver = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      let payload = {
        contactName: name.trim(),
        companyName: companyName ? companyName.trim() : null,
        email: email ? email.trim() : null,
        phone: phone ? phone.trim() : null,
        notes: notes ? notes.trim() : null,
        cities: selectedCities,
        ratePerMile: parseFloat(form.getFieldValue("rate_per_mile")),
        fixedRate: parseInt(form.getFieldValue("fixed_rate")),
        fixedRateMiles: parseInt(form.getFieldValue("fixed_rate_miles")),
        surchargePercentage: parseFloat(
          form.getFieldValue("surcharge_percentage")
        ),
      };

      let requestOptions = {
        method: "POST",
        headers: headers,
        credentials: "include",
        body: JSON.stringify(payload),
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/drivers`;

      try {
        const res = await fetch(url, requestOptions);
        const data = await res.json();
        if (data.error) {
          setAddDriverAlertClassName("alert-danger");
          setAddDriverAlertMessage(data.error);
        } else {
          // redirect to page
          navigate("/drivers", { replace: true });
        }
      } catch (error) {
        setAddDriverAlertClassName("alert-danger");
        setAddDriverAlertMessage(error);
      }
    }

    addDriver();
  }

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="m-2">
      <Button>
        <Link to="/drivers" style={{ textDecoration: "none" }}>
          <ArrowLeftOutlined /> back
        </Link>
      </Button>
      <h2 className="mb-4 text-center">Add Delivery Team</h2>
      <Divider />

      <Form form={form} name="add-lead" onFinish={handleSubmit}>
        <div className="d-flex flex-wrap justify-content-around">
          <div className="mx-4">
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter name.",
                },
                {
                  pattern: new RegExp("^[a-zA-Z0-9 '-]*$"),
                  message: "Only alphabet characters are allowed",
                },
              ]}
            >
              <Input
                placeholder="Name"
                onChange={(event) => {
                  setName(event.target.value.trim());
                }}
                allowClear
              />
            </Form.Item>

            <Form.Item
              name="companyName"
              label="Company Name"
              rules={[
                {
                  pattern: new RegExp("^[a-zA-Z0-9 '&-]*$"),
                  message: "Only alphabet characters are allowed",
                },
              ]}
            >
              <Input
                placeholder="Company Name"
                onChange={(event) => {
                  setCompanyName(event.target.value.trim());
                }}
                allowClear
              />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: "email",
                  message: "Not a valid email",
                },
              ]}
            >
              <Input
                placeholder="Email"
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </Form.Item>

            <Form.Item
              name="phone"
              label="Phone Number"
            >
              <InputMask
                mask="999-999-9999"
                value={phone}
                onChange={(event) => {
                  const cleanedValue = event.target.value.replace(/\D/g, "");
                  setPhone(cleanedValue);
                }}
              >
                {(inputProps) => (
                  <Input {...inputProps} placeholder="Phone Number" />
                )}
              </InputMask>
            </Form.Item>

            <Form.Item
              name="cities"
              label="Service Areas"
              rules={[
                {
                  required: true,
                  message: "Please select at least a city.",
                },
              ]}
            >
              <Select
                mode="multiple"
                showSearch
                placeholder="Select service areas"
                allowClear={true}
                options={cityOptions}
                filterOption={filterOption}
                onChange={(value) =>
                  setSelectedCities(value.map((index) => cities[index]["city"]))
                }
              />
            </Form.Item>
          </div>

          <div className="mx-4">
            <Form.Item
              name="rate_per_mile"
              label="Rate per mile"
              rules={[
                {
                  pattern: new RegExp("([0-9]*[.])?[0-9]+"),
                  message: "Please enter number.",
                },
              ]}
            >
              <Input placeholder="Rate Per Mile" allowClear />
            </Form.Item>

            <Form.Item
              name="surcharge_percentage"
              label="Surcharge Percentage"
              rules={[
                {
                  pattern: new RegExp("([0-9]*[.])?[0-9]+"),
                  message: "Please enter number.",
                },
              ]}
            >
              <Input placeholder="Surcharge Percentage" allowClear />
            </Form.Item>

            <Form.Item
              name="fixed_rate"
              label="Fixed Rate"
              rules={[
                {
                  pattern: new RegExp("[0-9]"),
                  message: "Please enter number.",
                },
              ]}
            >
              <Input placeholder="Fixed Rate" allowClear />
            </Form.Item>

            <Form.Item
              name="fixed_rate_miles"
              label="Fixed Rate Miles"
              rules={[
                {
                  pattern: new RegExp("([0-9]*[.])?[0-9]+"),
                  message: "Please enter number.",
                },
              ]}
            >
              <Input placeholder="Fixed Rate Miles" allowClear />
            </Form.Item>

            <Form.Item
              name="notes"
              label="Notes"
              rules={[
                {
                  max: 200,
                  message: "Can't exceed 200 characters",
                },
              ]}
            >
              <TextArea
                allowClear
                onChange={(event) => {
                  setNotes(event.target.value.trim());
                }}
                autoSize
              />
            </Form.Item>
          </div>
        </div>

        <Form.Item className="text-center">
          <Button type="primary" htmlType="submit">
            Add driver
          </Button>
        </Form.Item>
        <Alert
          message={addDriverAlertMessage}
          className={`mt-3 ${addDriverAlertClassName}`}
        />
      </Form>
    </div>
  );
}

export default AddDriver;
