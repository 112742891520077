import { Button, Divider } from 'antd'
import EditLocation from './EditLocation'
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import EditDeliveryRate from './EditDeliveryRate';

const ViewLocation = () => {


  return (
    <div className="m-2">
      <Button>
        <Link to="/locations" style={{ textDecoration: "none" }}>
          <ArrowLeftOutlined /> back
        </Link>
      </Button>
      <EditLocation />
      <Divider />
      <EditDeliveryRate />
      <Divider />
    </div>
  );
}

export default ViewLocation
