import { Divider, Table, Button, Input, Tooltip, Select} from "antd";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../context/AuthContext';
import { LeadStatusTag } from "../Tags/LeadStatusTag";
// import { LEAD_STATUS_ATTEMPTED, LEAD_STATUS_CLOSED_LOST, LEAD_STATUS_CLOSED_WON, LEAD_STATUS_NEW, LEAD_STATUS_QUOTE_SENT } from "../../utils/LeadUtils";
import { LEADSTATUS_OPTIONS } from "../../utils/ContainerUtils";
import './Leads.css'
import { SearchOutlined } from "@ant-design/icons";

const Leads = () => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("")
  const [filteredStatus, setFilteredStatus ] = useState("");
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  const { authTokens, userRole } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;

  useEffect(() => {
    const fetchData = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      let status = filteredStatus ? filteredStatus : ""

      let url = `${process.env.REACT_APP_BACKEND}/v1/leads?search_value=${searchValue}&lead_status=${status}`;
      if (selectedUser) {
        url = `${process.env.REACT_APP_BACKEND}/v1/leads?search_value=${searchValue}&lead_status=${status}&user_id=${selectedUser}`;
      }

      setLoading(true);

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        setLeads(data);
        getUserOptions(data);
        setLoading(false)
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [accessToken, searchValue, selectedUser, filteredStatus]);

  const getUserOptions = (leads) => {
    let options = [];
    let checked = new Set()
    for (let i = 0; i < leads.length; i++) {
      if (!checked.has(leads[i].userId)){
        let option = {
          value: leads[i].userId,
          label: leads[i].accountName,
        };
        options.push(option);
        checked.add(leads[i].userId);
      } else {
        continue
      }
    }
    setUserOptions(options);
  };

  const columns = [
    {
      title: "Name",
      key: "leadName",
      align: "center",
      fixed: "left",
      width: 150,
      sorter: (a, b) => a.leadName.localeCompare(b.leadName),
      render: (lead) => (
        <Link to={`/leads/${lead.leadId}`} style={{ textDecoration: "none" }}>
          <span className="hover-bold">{lead.leadName}</span>
        </Link>
      ),
    },
    {
      title: "Status",
      key: "leadStatus",
      align: "center",
      dataIndex: "leadStatus",
      width: 100,
      render: (status) => <LeadStatusTag status={status} />,
    },
    {
      title: "Email",
      dataIndex: "leadEmail",
      key: "leadEmail",
      align: "center",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Phone",
      dataIndex: "leadPhone",
      key: "leadPhone",
      align: "center",
      width: 150,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      align: "center",
      width: 100,
    },
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
      align: "center",
      width: 100,
    },
    {
      title: "Zipcode",
      dataIndex: "destinationZipcode",
      key: "destinationZipcode",
      align: "center",
      width: 100,
    },
    {
      title: "Nearby City",
      key: "nearbyCity",
      dataIndex: "nearbyCity",
      align: "center",
      width: 150,
    },
    {
      title: "Distance",
      dataIndex: "distanceInMile",
      key: "distance",
      align: "center",
      width: 100,
      render: (value) => {
        if (value) {
          return <span>{value.toFixed()} mi</span>;
        }
      },
    },
    {
      title: "Quote Price",
      dataIndex: "price",
      key: "price",
      align: "center",
      width: 100,
      render: (value) => {
        let fee = "$ " + value;
        if (value === 0) {
          fee = "";
        }
        return <>{fee}</>;
      },
    },
    {
      title: "Delivery Fee",
      dataIndex: "deliveryPrice",
      key: "deliveryPrice",
      align: "center",
      width: 100,
      render: (value) => {
        let fee = "$ " + value.toFixed(0);
        if (value === 0) {
          fee = "unknown";
        }
        return <>{fee}</>;
      },
    },
    {
      title: "Channel",
      key: "channel",
      dataIndex: "channel",
      align: "center",
      width: 150,
      render: (value) => {
        if (value === "facebook_leads") {
          return <>facebook leads</>;
        } else {
          return <>{value}</>;
        }
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      align: "center",
      width: 150,
      ellipsis: true,
      render: (notes) => (
        <Tooltip placement="topLeft" title={notes}>
          {notes}
        </Tooltip>
      ),
    },
    {
      title: "Account",
      dataIndex: "accountName",
      key: "accountName",
      align: "center",
      width: 100,
      hidden: userRole !== "user" ? false : true,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 150,
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (createdAt) =>
        new Date(createdAt).toLocaleDateString("en-US", {
          hour12: false,
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }),
    },
    {
      title: "Last Update",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      width: 150,
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
      render: (updatedAt) =>
        new Date(updatedAt).toLocaleDateString("en-US", {
          hour12: false,
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }),
    },
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   key: "x",
    //   align: "center",
    //   width: 150,
    //   render: (location) => (
    //     <Space size="middle">
    //       <Button type="primary">
    //         <Link
    //           to={`/locations/${location.id}`}
    //           style={{ textDecoration: "none" }}
    //         >
    //           View
    //         </Link>
    //       </Button>
    //       {/* <Button type="primary">Delete</Button> */}
    //     </Space>
    //   ),
    // },
  ].filter((item) => !item.hidden);

  // const leadSummary = useMemo(() => {
  //   let summary = {}
  //   summary[LEAD_STATUS_NEW] = 0
  //   summary[LEAD_STATUS_ATTEMPTED] = 0
  //   summary[LEAD_STATUS_QUOTE_SENT] = 0
  //   summary[LEAD_STATUS_CLOSED_WON] = 0
  //   summary[LEAD_STATUS_CLOSED_LOST] = 0
  //   if (leads) {
  //     leads.forEach((lead) => {
  //       if (lead.leadStatus in summary) {
  //         summary[lead.leadStatus] += 1;
  //       } else {
  //         summary[lead.leadStatus] = 1;
  //       }
  //     })
  //   }

  //   return (
  //     <div className="d-flex flex-wrap justify-content-around align-items-center">
  //       {Object.entries(summary).map(([key, value]) => (
  //         <div key={key}>
  //           <LeadStatusTag status={key}/><strong>:{value}</strong>
  //         </div>
  //       ))}
  //     </div>
  //   );
  // }, [leads])

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="m-2">
      <h2 className="my-4 text-center">Leads</h2>
      <Divider />
      <Button type="primary" className="me-4">
        <Link to="/add-lead" style={{ textDecoration: "none" }}>
          Add a new lead
        </Link>
      </Button>
      {userRole !== "user" && (
        <Button type="dashed">
          <Link to="/admin/leads-hubspot" style={{ textDecoration: "none" }}>
            Check leads from Hubspot
          </Link>
        </Button>
      )}
      <Divider />
      <div className="d-flex flex-wrap justify-content-start">
        <Select
          className="me-4"
          style={{ width: "150px" }}
          placeholder="Lead status"
          allowClear
          options={LEADSTATUS_OPTIONS}
          onChange={(value) => setFilteredStatus(value)}
        />
        {userRole !== "user" && (
          <Select
            showSearch
            className="me-4"
            style={{ width: "200px" }}
            placeholder="Account"
            allowClear
            options={userOptions}
            filterOption={filterOption}
            onChange={(value) => setSelectedUser(value)}
          />
        )}
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search name, email, phone number, zipcode, or nearby city"
          className="w-50 me-2"
          allowClear
          onChange={(event) => setSearchValue(event.target.value)}
        />
      </div>
      <Divider />
      {/* {leadSummary}
      <Divider /> */}
      <Table
        columns={columns}
        dataSource={leads}
        scroll={{ x: 1200, y: "100%" }}
        rowKey="leadId"
        loading={loading}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
        }}
      />
    </div>
  );
};

export default Leads;
