import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Form, Input, Spin, Modal } from "antd";
import AuthContext from "../../context/AuthContext";
import DeliveryRateCalculator from "./DeliveryRateCalculator";

const EditDeliveryRate = () => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;

  const { id } = useParams();
  const [locationId ] = useState(id);
  // const [errMessage, setErrMessage] = useState("")
  const [rateAvailable, setRateAvailable] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false)

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();

  useEffect(() => {
    const fetchData = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/delivery_rates?locationId=${id}`;

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        if (data.error) {
          // setErrMessage("No delivery rate data");
        } else {
          form.setFieldsValue({
            "rate_per_mile": data.ratePerMile,
            "fixed_rate": data.fixedRate,
            "fixed_rate_miles": data.fixedRateMiles,
            "surcharge_percentage": data.surchargePercentage,
          })
          setRateAvailable(true)
        }
        setLoading(false);
      } catch (error) {
        // setErrMessage("No delivery rate data")
        setLoading(false);
      }
    };

    fetchData();
  }, [accessToken, id, form]);

  const addDeliveryRate = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + accessToken);

    let payload = {
      locationId: parseInt(locationId),
      ratePerMile: parseFloat(form.getFieldValue("rate_per_mile")),
      fixedRate: parseInt(form.getFieldValue("fixed_rate")),
      fixedRateMiles: parseInt(form.getFieldValue("fixed_rate_miles")),
      surchargePercentage: parseFloat(form.getFieldValue("surcharge_percentage")),
    };

    let requestOptions = {
      method: "POST",
      headers: headers,
      credentials: "include",
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/delivery_rates`;

    try {
      const res = await fetch(url, requestOptions);
      const data = await res.json();
      if (data.error) {
        console.log(data.message);
      } else {
        // show success message then redirect
        console.log("add success")
        setAllowEdit(false);
        setRateAvailable(true)
        // redirect to page
        // navigate("/locations", { replace: true });
      }
    } catch (err) {
      console.log(err);
      // do sth with the error
      // setAlertClassName("alert-danger");
      // setAlertMessage(error);
    }
  };

  const editDeliveryRate = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + accessToken);

    let payload = {
      locationId: parseInt(locationId),
      ratePerMile: parseFloat(form.getFieldValue("rate_per_mile")),
      fixedRate: parseInt(form.getFieldValue("fixed_rate")),
      fixedRateMiles: parseInt(form.getFieldValue("fixed_rate_miles")),
      surchargePercentage: parseFloat(
        form.getFieldValue("surcharge_percentage")
      ),
    };

    const requestOptions = {
      method: "PATCH",
      headers: headers,
      credentials: "include",
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/delivery_rates`;

    try {
      const res = await fetch(url, requestOptions);
      const data = await res.json();
      if (data.error) {
        console.log(data.message);
      } else {
        // show success message then redirect
        console.log("edit success");
        setAllowEdit(false);
      }
    } catch (err) {
      console.log(err);
      // do sth with the error
      // setAlertClassName("alert-danger");
      // setAlertMessage(error);
    }
  };

  const handleSubmit = () => {
    if (!rateAvailable) {
      addDeliveryRate();
    } else {
      editDeliveryRate();
    }
  };

  return (
    <div className="text-center">
      {loading ? (
        <Spin size="large" />
      ) : (
        <div className="m-2">
          <h4 className="text-center mt-3">Delivery Rate</h4>
          <div className="text-center mb-3">
            delivery rate info for location ID: {locationId}
          </div>
          {rateAvailable &&
            <Button type="primary" onClick={() => setOpen(true)} className="mb-3">
              Check rate
            </Button>
          }
          <Modal
            title="Calculate delivery rate:"
            open={open}
            onCancel={() => setOpen(false)}
            centered={true}
            closeIcon={true}
            destroyOnClose={true}
            footer={null}
            okButtonProps={{ style: { display: "none" } }}
          >
            <DeliveryRateCalculator
              ratePerMile={parseFloat(form.getFieldValue("rate_per_mile"))}
              fixedRate={parseInt(form.getFieldValue("fixed_rate"))}
              fixedRateMiles={parseInt(form.getFieldValue("fixed_rate_miles"))}
              surchargePercentage={parseFloat(
                form.getFieldValue("surcharge_percentage")
              )}
            />
          </Modal>
          <Form
            form={form}
            name="add-delivery-rate"
            onFinish={handleSubmit}
            disabled={!allowEdit}
          >
            <div className="d-flex flex-wrap justify-content-around">
              <Form.Item
                name="rate_per_mile"
                label="Rate per mile"
                rules={[
                  {
                    required: true,
                    message: "Please enter rate per mile.",
                  },
                  {
                    pattern: new RegExp("([0-9]*[.])?[0-9]+"),
                    message: "Please enter number.",
                  },
                ]}
              >
                <Input placeholder="Rate Per Mile" allowClear />
              </Form.Item>

              <Form.Item
                name="surcharge_percentage"
                label="Surcharge Percentage"
                rules={[
                  {
                    required: true,
                    message: "Please enter the surcharge percentage.",
                  },
                  {
                    pattern: new RegExp("([0-9]*[.])?[0-9]+"),
                    message: "Please enter number.",
                  },
                ]}
              >
                <Input placeholder="Surcharge Percentage" allowClear />
              </Form.Item>
            </div>

            <div className="d-flex justify-content-around">
              <Form.Item
                name="fixed_rate"
                label="Fixed Rate"
                rules={[
                  {
                    required: true,
                    message: "Please enter the fixed rate.",
                  },
                  {
                    pattern: new RegExp("[0-9]"),
                    message: "Please enter number.",
                  },
                ]}
              >
                <Input placeholder="Fixed Rate" allowClear />
              </Form.Item>
              <Form.Item
                name="fixed_rate_miles"
                label="Fixed Rate Miles"
                rules={[
                  {
                    required: true,
                    message: "Please enter the fixed rate miles.",
                  },
                  {
                    pattern: new RegExp("([0-9]*[.])?[0-9]+"),
                    message: "Please enter number.",
                  },
                ]}
              >
                <Input placeholder="Fixed Rate Miles" allowClear />
              </Form.Item>
            </div>

            {allowEdit && (
              <div className="d-flex justify-content-around">
                <Form.Item>
                  <Button className="mx-2" type="primary" htmlType="submit">
                    {!rateAvailable ? "Submit" : "Update"}
                  </Button>
                  <Button
                    onClick={() => {
                      setAllowEdit(false);
                      // setErrMessage("")
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form>

          {!allowEdit && (
            <div className="d-flex justify-content-around">
              <Button className="mx-2" onClick={() => setAllowEdit(true)}>
                {rateAvailable ? "Edit" : "Add"}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default EditDeliveryRate;
