import { Tag } from 'antd';
import { LEAD_STATUS_ATTEMPTED, LEAD_STATUS_CLOSED_LOST, LEAD_STATUS_CLOSED_WON, LEAD_STATUS_NEW, LEAD_STATUS_QUOTE_SENT, LEAD_STATUS_VALUES } from '../../utils/LeadUtils';

export const LeadStatusTag = ({status}) => {

  let color = ""
  switch (status) {
    case LEAD_STATUS_NEW:
      color = "#32CD32";
      break;
    case LEAD_STATUS_ATTEMPTED:
      color = "#FFA07A";
      break;
    case LEAD_STATUS_QUOTE_SENT:
      color = "#108ee9";
      break;
    case LEAD_STATUS_CLOSED_WON:
      color = "#FFD700";
      break;
    case LEAD_STATUS_CLOSED_LOST:
      color = "#D3D3D3";
      break;
    default:
      color = "#D3D3D3";
  }

  return <Tag color={color}>{LEAD_STATUS_VALUES[status]}</Tag>;
}
