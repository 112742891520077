import { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import { Divider, Button, Table, Input, Tooltip, Tag } from "antd";
import { Link } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { CITIES } from "../../utils/LocationUtils";

const Drivers = () => {
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;

  useEffect(() => {
    const fetchData = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      let url = `${process.env.REACT_APP_BACKEND}/v1/drivers?search_value=${searchValue}`;

      setLoading(true);

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        setDrivers(data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }

    fetchData();
  }, [accessToken, searchValue]);

  const columns = [
    {
      title: "Name",
      key: "contactName",
      align: "center",
      fixed: "left",
      width: 150,
      sorter: (a, b) => a.contactName.localeCompare(b.contactName),
      render: (driver) => (
        <Link to={`/driver/${driver.driverId}`} style={{ textDecoration: "none" }}>
          <span className="hover-bold">{driver.contactName}</span>
        </Link>
      ),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      align: "center",
      width: 100,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Serviced Cities",
      dataIndex: "cities",
      key: "cities",
      align: "center",
      width: 150,
      // ellipsis: true,
      render: (cities) => (
        <Tooltip
          placement="topLeft"
          title={cities.map((city) => CITIES[city]).join(", ")}
        >
          {cities.map((city) => (
            <Tag key={city}>{CITIES[city]}</Tag>
          ))}
        </Tooltip>
      ),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      align: "center",
      width: 150,
      ellipsis: true,
      render: (notes) => (
        <Tooltip placement="topLeft" title={notes}>
          {notes}
        </Tooltip>
      ),
    },
  ];

  return (
    <div className="m-2">
      <h2 className="my-4 text-center">Drivers</h2>
      <Divider />
      <Button type="primary" className="me-4">
        <Link to="/add-driver" style={{ textDecoration: "none" }}>
          Add a new driver
        </Link>
      </Button>
      <Divider />
      <Input
        prefix={<SearchOutlined />}
        placeholder="Search name or serviced city"
        className="w-50 me-2"
        allowClear
        onChange={(event) => setSearchValue(event.target.value)}
      />
      <Divider />
      <Table
        columns={columns}
        dataSource={drivers}
        scroll={{ x: 1200, y: "100%" }}
        rowKey="driverId"
        loading={loading}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
        }}
      />
    </div>
  );
};

export default Drivers;
