import { Select, Button, Popconfirm, Space, message } from "antd";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const LeadTransfer = ({leadId}) => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/users/others`;

      setLoading(true)

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        getUserOptions(data);
        setLoading(false)
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [accessToken]);

  const getUserOptions = (users) => {
    let options = [];
    for (let i = 0; i < users.length; i++) {
      let option = {
        value: users[i].id,
        label: users[i].name !== " " ? users[i].name : users[i].username,
      };
      options.push(option);
    }
    setUserOptions(options);
  };

  const handleTransfer = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + accessToken);

    let payload = {
      leadID: parseInt(leadId),
      userID: selectedUser,
    }

    const requestOptions = {
      method: "PATCH",
      headers: headers,
      credentials: "include",
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/leads/transfer`;

    try {
      let response = await fetch(url, requestOptions);
      if (response.ok) {
        message.success("Lead transferred");
        // navigate to leads page
        navigate("/leads", { replace: true });
      } else {
        message.error("Failed to transfer the lead");
      }
    } catch (err) {
      message.error("Error transferring lead");
    }
  }

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="mt-2 mb-4 mx-4 d-flex flex-column align-items-end">
      <span className="text-danger mb-2">
        <em>*Transfer this lead to a teammate</em>
      </span>
      <Space size="middle">
        <Select
          showSearch
          allowClear
          placeholder="Select user"
          options={userOptions}
          disabled={loading}
          filterOption={filterOption}
          onChange={(value) => setSelectedUser(value)}
        />
        <Popconfirm
          title={
            <span className="text-danger">
              <>Confirm transferring this lead?</>
            </span>
          }
          onConfirm={handleTransfer}
          placement="topRight"
        >
          <Button type="dashed" danger disabled={!selectedUser}>
            Confirm
          </Button>
        </Popconfirm>
      </Space>
    </div>
  );
}

export default LeadTransfer;
