import { useState, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { LockTwoTone, MailTwoTone } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import Alert from "../Alert";

const PasswordLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onFinish = () => {
    login(email.toLowerCase(), password);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  let { login, alertMessage, alertClassName, setAlertClassName } =
    useContext(AuthContext);

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-6 text-center">
            <p className="fw-bold">
              Please enter your registered email and password.
            </p>
            <Form
              name="normal_login"
              className="mt-4"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Not a valid email",
                  },
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input
                  prefix={<MailTwoTone className="site-form-item-icon" />}
                  placeholder="Email"
                  onChange={(event) => {
                    setEmail(event.target.value);
                    setAlertClassName("d-none");
                  }}
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockTwoTone className="site-form-item-icon" />}
                  placeholder="Password"
                  onChange={(event) => {
                    setPassword(event.target.value);
                    setAlertClassName("d-none");
                  }}
                />
              </Form.Item>

              <Form.Item wrapperCol={{ span: 32 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Log In
                </Button>
              </Form.Item>
              <Form.Item>
                <Alert message={alertMessage} className={alertClassName} />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordLogin;
