import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoutes";
import { AuthProvider } from "./context/AuthContext";
import Home from './components/Home/Home.jsx'
import EditInventory from "./components/Inventory/EditInventory";
import ManageInventory from "./components/Inventory/ManageInventory";
import SearchInventory from "./components/Inventory/SearchInventory";
import AppLayout from "./components/AppLayout";
import Drivers from './components/Drivers/Drivers.jsx'
import UsersList from './components/Users/UsersList'
import { Layout } from "antd";
import NavMenu from "./components/Navigation/NavMenu";
import HeaderMenu from "./components/Navigation/HeaderMenu";
import { useEffect, useState } from "react";
import Locations from "./components/Locations/Locations";
import AddLocation from "./components/Locations/AddLocation";
import AddInventory from "./components/Inventory/AddInventory";
import Signup from "./components/Users/Signup";
import NotFoundPage from "./components/NotFoundPage";
import ViewLocation from "./components/Locations/ViewLocation";
import AdminRoutes from "./utils/AdminRoutes";
import Profile from "./components/Users/Profile";
import AdminEditUser from "./components/Users/AdminEditUser";
import InventorySummary from "./components/Inventory/InventorySummary.jsx";
import InventoryCityList from "./components/Inventory/InventoryCityList";
import Leads from "./components/Leads/Leads";
import AddLead from "./components/Leads/AddLead";
import LeadDetails from "./components/Leads/LeadDetails";
import LeadsFromHubspot from "./components/Leads/LeadsFromHubspot";
import TodosList from "./components/Todos/TodosList";
import DeliveryDetails from "./components/Home/DeliveryDetails.jsx";
import ManagePrices from "./components/Inventory/ManagePrices.jsx";
import Login from "./components/Users/Login.jsx";
import AddDriver from "./components/Drivers/AddDriver.jsx";
import DriverDetails from "./components/Drivers/DriverDetails.jsx";

function App() {
  const { Header, Sider, Content } = Layout;

  const headerStyle = {
    color: "#fff",
    maxHeight: "8vh",
    display: "flex",
    backgroundColor: "#002140",
  };
  const contentStyle = {
    height: "92vh",
    overflow: "auto",
  };
  const siderStyle = {
    textAlign: "center",
    lineHeight: "120px",
    color: "#fff",
    // backgroundColor: "#002140",
  };
  const layoutStyle = {
    height: "100vh",
  };

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    // Set the initial state of collapsed based on screen width
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Layout style={layoutStyle}>
        <Router>
          <AuthProvider>
            <Header style={headerStyle}>
              <HeaderMenu />
            </Header>
            <Layout hasSider>
              <Sider
                style={siderStyle}
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                theme="light"
              >
                <NavMenu />
              </Sider>
              <Content style={contentStyle}>
                <Routes>
                  <Route element={<AppLayout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/inventory" element={<InventorySummary />} />
                    <Route
                      path="/inventory/search"
                      element={<SearchInventory />}
                    />
                    <Route
                      path="/delivery-details"
                      element={<DeliveryDetails />}
                    />
                  </Route>

                  <Route element={<PrivateRoutes />}>
                    <Route
                      path="/inventory/city/:city"
                      element={<InventoryCityList />}
                    />
                    <Route path="/locations" element={<Locations />} />
                    <Route path="/locations/:id" element={<ViewLocation />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/leads" element={<Leads />} />
                    <Route path="/leads/:id" element={<LeadDetails />} />
                    <Route path="/add-lead" element={<AddLead />} />
                    <Route path="/tasks" element={<TodosList />} />
                    <Route path="/manage-prices" element={<ManagePrices />} />
                  </Route>

                  <Route element={<AdminRoutes />}>
                    <Route path="/add-inventory" element={<AddInventory />} />
                    <Route path="/inventory/:id" element={<EditInventory />} />
                    <Route
                      path="/manage-inventory"
                      element={<ManageInventory />}
                    />
                    <Route path="/drivers" element={<Drivers />} />
                    <Route path="/driver/:id" element={<DriverDetails />} />
                    <Route path="/add-driver" element={<AddDriver />} />
                    <Route path="/admin/users" element={<UsersList />} />
                    <Route
                      path="/admin/users/:id"
                      element={<AdminEditUser />}
                    />
                    <Route path="/add-location" element={<AddLocation />} />
                    ·{" "}
                    <Route
                      path="/admin/leads-hubspot"
                      element={<LeadsFromHubspot />}
                    />
                  </Route>

                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Content>
            </Layout>
          </AuthProvider>
        </Router>
      </Layout>
    </div>
  );
}

export default App;
