import { useState, useContext } from "react";
import { MailTwoTone } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import AuthContext from "../../context/AuthContext";
import Alert from "../Alert";

const OTPLogin = () => {
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [step, setStep] = useState(1); // 1: Email input, 2: OTP input
  const {
    loginWithOTP,
    alertMessage,
    setAlertMessage,
    alertClassName,
    setAlertClassName,
  } = useContext(AuthContext);

  const sendOTP = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/otp/send`

    try {
      let response = await fetch(url, requestOptions);
      if (response.ok) {
        if (step === 2) {
          setAlertClassName("alert-success");
          setAlertMessage("We sent you a new code.");
        }
      }
    } catch (error) {
      setAlertClassName("alert-danger");
      setAlertMessage("Failed to send code. Please try again later.");
    }
  };

  const handleSendOTP = async () => {
    if (step === 1) {
      await sendOTP();
      setStep(2); // Proceed to OTP input step
    } else {
      await sendOTP();
    }
  };

  const onFinishOTPStep = () => {
    loginWithOTP(email.toLowerCase(), otp);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-6 text-center">
            {step === 1 ? (
              <div>
                <p className="fw-bold">
                  Please enter your registered email. <br />
                  We’ll email you a sign-in code.
                </p>
                <Form
                  name="otp_login"
                  className="mt-4"
                  initialValues={{ remember: true }}
                  onFinish={handleSendOTP}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Not a valid email",
                      },
                      {
                        required: true,
                        message: "Please input your email",
                      },
                    ]}
                  >
                    <Input
                      prefix={<MailTwoTone className="site-form-item-icon" />}
                      placeholder="Email address"
                      onChange={(event) => {
                        setEmail(event.target.value);
                        setAlertClassName("d-none");
                      }}
                    />
                  </Form.Item>

                  <Form.Item wrapperCol={{ span: 32 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Alert message={alertMessage} className={alertClassName} />
                  </Form.Item>
                </Form>
              </div>
            ) : (
              <div>
                <p className="fw-bold">
                  Please enter the code we sent to your email.
                </p>
                <Form
                  name="otp_login"
                  className="mt-4"
                  initialValues={{ remember: true }}
                  onFinish={onFinishOTPStep}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    name="otp"
                    rules={[
                      {
                        required: true,
                        message: "Please input your OTP!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Sign-in code"
                      onChange={(event) => {
                        setOTP(event.target.value);
                        setAlertClassName("d-none");
                      }}
                    />
                  </Form.Item>

                  <Form.Item wrapperCol={{ span: 32 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      Log in
                    </Button>
                    <Button
                      type="default"
                      onClick={handleSendOTP}
                      style={{ width: "100%", marginTop: "12px" }}
                    >
                      Resend code
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Alert message={alertMessage} className={alertClassName} />
                  </Form.Item>
                </Form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OTPLogin;
