import {Link} from 'react-router-dom'
import AuthContext from "../../context/AuthContext";
import { useContext } from "react";


export const QuickLinksWidget = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <div className="h-100 border p-4 bg-light">
      <h5 className="m-2 mb-3 text-start">Quick Links </h5>
      <div className="m-4">
        <h6 className="text-start mb-3">
          1.{" "}
          <Link to={`/inventory/search`}>
            Find a container
          </Link>{" "}
          and get an instant quote
        </h6>
        <h6 className="text-start mb-3">
          2. Check out today's{" "}
          <Link to={`/inventory`}>
            inventory summary
          </Link>{" "}
        </h6>
        <h6 className="text-start mb-3">
          3. Visit our{" "}
          <Link to="https://solidboxcontainer.com" target="_blank">
            webstore
          </Link>
        </h6>
        { isLoggedIn
          ?
          <h6 className="text-start mb-3">
            4. Start your day with{" "}
            <Link to={`/tasks`}>
              checking on your tasks
            </Link>
          </h6>
          :
          <h6 className="text-start mb-3">
            4.{" "}
            <Link to={`/tasks`}>
              Log in
            </Link>{" "}
            to manage your tasks and leads
          </h6>
      }
      </div>
    </div>
  );
}
