import { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import { Button, Form, Input, Divider, Space, Spin } from "antd";
import Alert from "../Alert";

const Profile = () => {
  const { authTokens } = useContext(AuthContext);
  const [email, setEmail] = useState("")
  const [firstname, setFirstname] = useState()
  const [lastname, setLastname] = useState()
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("");
  const [pwAlertMessage, setPwAlertMessage] = useState("");
  const [pwAlertClassName, setPwAlertClassName] = useState("d-none");
  const [profileAlertMessage, setProfileAlertMessage] = useState("");
  const [profileAlertClassName, setProfileAlertClassName] = useState("d-none");
  const [loading, setLoading] = useState(true);

  const [profileForm] = Form.useForm()
  const [passwordForm] = Form.useForm()

  const onPasswordCancel = () => {
    passwordForm.resetFields();
    setPwAlertClassName("d-none")
  };

  // get profile
  useEffect(() => {
    const setData = (userProfile) => {
      if (userProfile) {
        setEmail(userProfile.email);
        setFirstname(userProfile.firstname);
        setLastname(userProfile.lastname);
        profileForm.setFieldsValue({
          email: userProfile.email,
          username: userProfile.username,
          firstname: userProfile.firstname,
          lastname: userProfile.lastname,
        });
      }
    };

    const fetchData = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + authTokens.accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/settings`;

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        setData(data)
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false)
      }
    };

    fetchData();
  }, [authTokens, profileForm]);

  const onProfileFinish = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + authTokens.accessToken);

    let payload = {
      firstname: firstname.trim(),
      lastname: lastname.trim(),
    };

    const requestOptions = {
      method: "PUT",
      headers: headers,
      credentials: "include",
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/settings`;

    setLoading(true)
    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      if (data.error) {
        setProfileAlertClassName("alert-danger");
        setProfileAlertMessage(data.error);
      } else {
        setProfileAlertClassName("alert-success");
        setProfileAlertMessage("Profile updated");
      }
    } catch (err) {
      setProfileAlertClassName("alert-danger");
      setProfileAlertMessage(err);
    } finally {
      setLoading(false)
    }
  };

  const onProfileFinishFailed = (errorInfo) => {
    console.log("Profile Update Failed:", errorInfo);
  };

  const onPasswordFinish = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + authTokens.accessToken);

    let payload = {
      email: email,
      oldPassword: oldPassword,
      newPassword: newPassword,
    };

    const requestOptions = {
      method: "PUT",
      headers: headers,
      credentials: "include",
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/settings/password`;

    setLoading(true)

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      if (data && data.error) {
        setPwAlertClassName("alert-danger");
        setPwAlertMessage(data.error)
      } else {
        setPwAlertClassName("alert-success");
        setPwAlertMessage("Password updated");
      }
    } catch (err) {
      setPwAlertClassName("alert-danger");
      setPwAlertMessage(err);
    } finally {
      setLoading(false)
    }
  };

  const onPasswordFinishFailed = (errorInfo) => {
    console.log("PW Update Failed:", errorInfo);
  };

  return (
    <div className="m-2">
      <h2 className="my-4">Profile</h2>
      <Divider />
      {loading ? (
        <Spin size="large" />
      ) : (
        <div>
          <h6 className="mb-4">Set up Personal Profile</h6>
          <Form
            form={profileForm}
            name="profile"
            initialValues={{ remember: true }}
            onFinish={onProfileFinish}
            onFinishFailed={onProfileFinishFailed}
            autoComplete="off"
          >
            <Form.Item name="email" label="Email">
              <Input disabled />
            </Form.Item>
            <Form.Item name="username" label="Username">
              <Input disabled />
            </Form.Item>

            <Form.Item
              name="firstname"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your first name",
                },
              ]}
            >
              <Input
                maxLength={20}
                value={firstname}
                onChange={(event) => {
                  setFirstname(event.target.value);
                }}
                allowClear
              />
            </Form.Item>

            <Form.Item
              name="lastname"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your last name",
                },
              ]}
            >
              <Input
                maxLength={20}
                value={lastname}
                onChange={(event) => {
                  setLastname(event.target.value);
                }}
                allowClear
              />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Space>
            </Form.Item>
            <Alert
              message={profileAlertMessage}
              className={profileAlertClassName}
            />
          </Form>
          <Divider />
          <h6 className="mb-4">Change Password</h6>
          <Form
            form={passwordForm}
            name="change_password"
            initialValues={{ remember: true }}
            onFinish={onPasswordFinish}
            onFinishFailed={onPasswordFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="oldPassword"
              label="Old Password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter your old password!",
                },
                {
                  min: 6,
                  message: "Password must be minimum 6 characters.",
                },
              ]}
            >
              <Input.Password
                placeholder="Old Password"
                value={oldPassword}
                onChange={(event) => {
                  setOldPassword(event.target.value);
                  setPwAlertClassName("d-none");
                }}
              />
            </Form.Item>

            <Form.Item
              name="newPassword"
              label="New Password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter a new password!",
                },
                {
                  min: 6,
                  message: "Password must be minimum 6 characters.",
                },
                {
                  pattern: new RegExp("^[a-zA-Z0-9!@#$%^&]*$"),
                  message:
                    "Only the following special characters are allowed: !@#$%^&",
                },
              ]}
            >
              <Input.Password
                placeholder="Password"
                value={newPassword}
                onChange={(event) => {
                  setNewPassword(event.target.value);
                  setPwAlertClassName("d-none");
                }}
              />
            </Form.Item>

            <Form.Item
              name="confirmNewPassword"
              label="Confirm New Password"
              dependencies={["newPassword"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm new password" />
            </Form.Item>

            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Confirm
                </Button>
                <Button onClick={onPasswordCancel}>Cancel</Button>
              </Space>
            </Form.Item>
            <Alert message={pwAlertMessage} className={pwAlertClassName} />
          </Form>
        </div>
      )}
    </div>
  );
};

export default Profile;
