import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import {
  Divider,
  Table,
  Input,
  Radio,
  Tooltip,
  Button,
  Form,
  Popconfirm,
  Typography,
  message
} from "antd";
import { CITIES } from "../../utils/LocationUtils";
import "./Inventory.css";
import { SearchOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { EditableCell } from "./EditableCell";


const ManagePrices = () => {
  const [containers, setContainers] = useState([]);
  const { authTokens, userRole } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;

  const [searchValue, setSearchValue] = useState("");
  const [condition, setCondition] = useState("CW");
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    const fetchData = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: headers,
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/containers/summary_prices?search_value=${searchValue}&condition=${condition}`;

      setLoading(true);

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        if (data.error) {
          console.log(data.message);
        } else {
          setContainers(data);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [searchValue, condition, accessToken, updated]);

  const [editingCity, setEditingCity] = useState("");
  const isEditing = (record) => record.city === editingCity;
  const editRecord = (record) => {
    form.setFieldsValue({
      "20st": record["20st"],
      "20hc": record["20hc"],
      "40st": record["40st"],
      "40hc": record["40hc"],
      "40hcdd": record["40hcdd"],
      ...record,
    });
    setEditingCity(record.city);
  };

  const submitEdit = async () => {
    const price20st = form.getFieldValue("20st")
    const price20hc = form.getFieldValue("20hc")
    const price40st = form.getFieldValue("40st")
    const price40hc = form.getFieldValue("40hc")
    const price40hcdd = form.getFieldValue("40hcdd")

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + accessToken);

    let payload = {
      city: editingCity,
      condition: condition,
      st20: price20st,
      hc20: price20hc,
      st40: price40st,
      hc40: price40hc,
      hcdd40: price40hcdd,
    }

    const requestOptions = {
      method: "PATCH",
      headers: headers,
      credentials: "include",
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/containers/prices`;

    try {
      let response = await fetch(url, requestOptions);
      if (response.ok) {
        message.success("Price updated");
        setUpdated((prev) => !prev);
      } else {
        message.error("Failed to update prices");
      }
    } catch (err) {
      message.error("Error updating prices");
    } finally {
      setEditingCity("");
    }
  }

  const columns = [
    {
      title: "City",
      key: "city",
      fixed: "left",
      align: "center",
      ellipsis: true,
      render: (container) => (
        <span className="fw-bold">{CITIES[container.city]}</span>
      ),
    },
    {
      title: (
        <Tooltip placement="topLeft" title="20ft Standard">
          20ST
        </Tooltip>
      ),
      dataIndex: "20st",
      key: "20st",
      align: "center",
      render: (price) => <>$ {price.toLocaleString("en-US")}</>,
      editable: true,
    },
    {
      title: (
        <Tooltip placement="topLeft" title="20ft High Cube">
          20HC
        </Tooltip>
      ),
      dataIndex: "20hc",
      key: "20hc",
      align: "center",
      render: (price) => <>$ {price.toLocaleString("en-US")}</>,
      editable: true,
    },
    {
      title: (
        <Tooltip placement="topLeft" title="40ft Standard">
          40ST
        </Tooltip>
      ),
      dataIndex: "40st",
      key: "40st",
      align: "center",
      render: (price) => <>$ {price.toLocaleString("en-US")}</>,
      editable: true,
    },
    {
      title: (
        <Tooltip placement="topLeft" title="40ft High Cube">
          40HC
        </Tooltip>
      ),
      dataIndex: "40hc",
      key: "40hc",
      align: "center",
      render: (price) => <>$ {price.toLocaleString("en-US")}</>,
      editable: true,
    },
    {
      title: (
        <Tooltip placement="topLeft" title="40ft High Cube Double Door">
          40HCDD
        </Tooltip>
      ),
      dataIndex: "40hcdd",
      key: "40hcdd",
      align: "center",
      render: (price) => <>$ {price.toLocaleString("en-US")}</>,
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      hidden: userRole === "super" || userRole === "admin" ? false : true,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Popconfirm
              title="Update prices?"
              onConfirm={() => submitEdit(record)}
            >
              <Typography.Link
                style={{
                  marginRight: 8,
                }}
              >
                Save
              </Typography.Link>
            </Popconfirm>
            <Typography.Link onClick={() => setEditingCity("")}>
              Cancel
            </Typography.Link>
          </span>
        ) : (
          <Typography.Link
            disabled={editingCity !== ""}
            onClick={() => editRecord(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ].filter((item) => !item.hidden);

  let lastUpdatedAt = new Date("2023-01-01T00:00:00.000");
  if (containers) {
    for (let i = 0; i < containers.length; i++) {
      if (new Date(containers[i].updatedAt) > lastUpdatedAt) {
        lastUpdatedAt = new Date(containers[i].updatedAt);
      }
    }
  }

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "number",
        dataIndex: col.dataIndex,
        title: "price",
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div className="m-2">
      <Button>
        <Link to="/manage-inventory" style={{ textDecoration: "none" }}>
          <ArrowLeftOutlined /> back
        </Link>
      </Button>
      <h2 className="text-center mb-4">Price Summary</h2>
      <Divider />
      <div className="d-flex flex-wrap justify-content-between align-items-center">
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search by city"
          className="w-50"
          allowClear
          onChange={(event) => setSearchValue(event.target.value)}
        />
        {containers && containers.length !== 0 && (
          <div className="mx-2">
            <em>
              Last sync at:{" "}
              {lastUpdatedAt.toLocaleString("en-US", { hour12: false })}
            </em>
          </div>
        )}
      </div>
      <Divider />
      <div className="text-center">
        <Radio.Group
          defaultValue="CW"
          buttonStyle="solid"
          size="large"
          onChange={(event) => setCondition(event.target.value)}
        >
          <Radio.Button value="CW">Used</Radio.Button>
          <Radio.Button value="OT">New</Radio.Button>
        </Radio.Group>
      </div>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns}
          dataSource={containers}
          className="mt-3"
          scroll={{ x: 1200, y: "100%" }}
          rowKey="city"
          loading={loading}
        />
      </Form>
    </div>
  );
};

export default ManagePrices;
