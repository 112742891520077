import { Select, Input, Button, Form, Divider } from "antd";
import { useState } from "react";
import { useFetchCities } from "../../utils/LocationUtils";
import { GetMiles } from "../../utils/LocationUtils";
import Alert from "../Alert";

const DeliveryRateCalculatorModal = () => {
  const [form] = Form.useForm();

  const { cities, cityOptions } = useFetchCities();
  const [selectedCity, setSelectedCity] = useState();
  const [zipcode, setZipcode] = useState("");
  const [deliveryFee, setDeliveryFee] = useState(undefined)
  const [loading, setLoading] = useState(false);
  const [calculateAlertMessage, setCalculateAlertMessage] = useState("");
  const [calculateAlertClassName, setCalculateAlertClassName] =
    useState("d-none");

  const handleSubmit = async () => {
    setLoading(true);
    setDeliveryFee(undefined)
    setCalculateAlertClassName("d-none")

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: headers,
      credentials: "include",
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/delivery_fee?location_id=${selectedCity.id}&from_zipcode=${selectedCity.zipcode}&to_zipcode=${zipcode}`;

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      if (data.error) {
        setCalculateAlertClassName("alert-danger");
        setCalculateAlertMessage("Can not get an estimate. Try again later");
      } else {
        setDeliveryFee(data)
        setCalculateAlertClassName("d-none");
        setCalculateAlertMessage("");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setCalculateAlertClassName("alert-danger");
      setCalculateAlertMessage("Can not get an estimate. Try again later");
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="m-2">
      <h4 className="text-center my-2">Delivery Fee Estimator</h4>
      <Divider />
      <div className="d-flex align-items-center justify-content-around">
        <Form form={form} name="delivery-calculator" onFinish={handleSubmit}>
          <div className="d-flex justify-content-around align-items-center">
            <div className="mx-4 align-items-center">
              <Form.Item
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please select a city.",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select city"
                  allowClear={true}
                  options={cityOptions}
                  disabled={loading}
                  filterOption={filterOption}
                  onChange={(value) => setSelectedCity(cities[value])}
                />
              </Form.Item>

              <Form.Item
                name="zipcode"
                rules={[
                  {
                    required: true,
                    message: "Please enter a zipcode.",
                  },
                  {
                    pattern: new RegExp("^[0-9]{5}"),
                    message: "Not a valid zipcode.",
                  },
                ]}
              >
                <Input
                  placeholder="Zipcode"
                  maxLength={5}
                  onChange={(event) => {
                    setZipcode(event.target.value);
                  }}
                  allowClear
                />
              </Form.Item>
            </div>

            <div className="mx-4 align-items-center">
              <Form.Item>
                <Button
                  className="mx-2"
                  type="primary"
                  loading={loading}
                  htmlType="submit">
                  Estimate
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>

      {deliveryFee && (
        <div className="m-2 text-center p-2 border border-secondary rounded fs-6">
          <div>
            Distance:&nbsp;
            <span className="fw-bold text-primary">
              {Math.round(GetMiles(deliveryFee.distanceInMeters))} miles
            </span>
          </div>
          <div>
            Estimated delivery fee:&nbsp;
            <span className="fw-bold text-primary">
              ${Math.round(deliveryFee.deliveryFee)}
            </span>
          </div>
        </div>
      )}
      <Alert
        message={calculateAlertMessage}
        className={`mt-3 ${calculateAlertClassName}`}
      />
    </div>
  );
};

export default DeliveryRateCalculatorModal;
