import { useState, useContext, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Button, Form, Input, Select, Divider} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import AuthContext from "../../context/AuthContext";
import { CONDITION_OPTIONS, SIZE_OPTIONS, LEADSTATUS_OPTIONS, CHANNEL_OPTIONS } from "../../utils/ContainerUtils";
import { GetMeters, GetMiles } from "../../utils/LocationUtils";
import Alert from "../Alert";
import InputMask from "react-input-mask";

function AddLead() {
  const location = useLocation()

  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;
  const [size, setSize] = useState("");
  const [condition, setCondition] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [leadEmail, setLeadEmail] = useState("")
  const [leadName, setLeadName] = useState("")
  const [leadPhone, setLeadPhone] = useState("")
  const [price, setPrice] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [notes, setNotes] = useState("");
  const [leadStatus, setLeadStatus] = useState("new");
  const [channel, setChannel] = useState("");
  const [nearbyCity, setNearbyCity] = useState("");
  const [distanceInMile, setDistanceInMile] = useState(0);

  const [addLeadAlertMessage, setAddLeadAlertMessage] = useState("");
  const [addLeadAlertClassName, setAddLeadAlertClassName] =
    useState("d-none");

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { TextArea } = Input;

  useEffect(() => {
    if (location && location.state) {
      form.setFieldsValue({
        size: location.state.size,
        condition: location.state.condition,
        zipcode: location.state.zipcode,
        price: location.state.price.toFixed(),
        deliveryFee: location.state.deliveryFee,
        nearbyCity: location.state.nearbyCity,
        distance: Math.round(GetMiles(location.state.distanceInMeter)),
      });
      setPrice(location.state.price)
      setDeliveryFee(location.state.deliveryFee);
    }
  }, [location, form]);


  const handleSubmit = () => {
    const addLead = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      let payload = {
        leadName: leadName,
        leadEmail: leadEmail ? leadEmail : null,
        leadPhone: leadPhone ? leadPhone : null,
        size:
          location && location.state ? location.state.size : size ? size : null,
        condition:
          location && location.state
            ? location.state.condition
            : condition
            ? condition
            : null,
        price:
            price
            ? parseInt(price)
            : null,
        deliveryPrice:
            deliveryFee
            ? parseInt(deliveryFee)
            : null,
        destinationZipcode:
          location && location.state
            ? location.state.zipcode
            : zipcode
            ? zipcode
            : null,
        distanceInMeter:
          location && location.state
            ? location.state.distanceInMeter
            : distanceInMile
            ? GetMeters(distanceInMile)
            : null,
        notes: notes ? notes : null,
        leadStatus: leadStatus,
        channel: channel ? channel : null,
        nearbyCity: nearbyCity ? nearbyCity : null,
      };

      let requestOptions = {
        method: "POST",
        headers: headers,
        credentials: "include",
        body: JSON.stringify(payload),
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/leads`;

      try {
        const res = await fetch(url, requestOptions);
        const data = await res.json();
        if (data.error) {
          setAddLeadAlertClassName("alert-danger");
          setAddLeadAlertMessage(data.error);
        } else {
          // redirect to page
          navigate("/leads", { replace: true });
        }
      } catch (error) {
        setAddLeadAlertClassName("alert-danger");
        setAddLeadAlertMessage(error);
      }
    }

    addLead();
  }

  return (
    <div className="m-2">
      <Button>
        <Link to="/leads" style={{ textDecoration: "none" }}>
          <ArrowLeftOutlined /> back
        </Link>
      </Button>
      <h2 className="mb-4 text-center">Add New Lead</h2>
      <Divider />

      <Form form={form} name="add-lead" onFinish={handleSubmit}>
        <div className="d-flex flex-wrap justify-content-around">
          <div className="mx-4">
            <Form.Item
              name="leadStatus"
              label="Lead Status"
              rules={[
                {
                  required: true,
                  message: "Please select a lead status.",
                },
              ]}
            >
              <Select
                placeholder="Select the leas status"
                options={LEADSTATUS_OPTIONS}
                onChange={(value) => setLeadStatus(value)}
              />
            </Form.Item>

            <Form.Item
              name="leadName"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter the name of the lead.",
                },
                {
                  pattern: new RegExp("^[a-zA-Z0-9 '-]*$"),
                  message: "Only alphabet characters are allowed",
                },
              ]}
            >
              <Input
                placeholder="Lead Name"
                onChange={(event) => {
                  setLeadName(event.target.value.trim());
                }}
                allowClear
              />
            </Form.Item>

            <Form.Item
              name="leadEmail"
              label="Email"
              rules={[
                {
                  type: "email",
                  message: "Not a valid email",
                },
              ]}
            >
              <Input
                placeholder="Email"
                onChange={(event) => {
                  setLeadEmail(event.target.value);
                }}
              />
            </Form.Item>

            <Form.Item
              name="leadPhone"
              label="Phone Number"
            >
              <InputMask
                mask="999-999-9999"
                value={leadPhone}
                onChange={(event) => {
                  const cleanedValue = event.target.value.replace(/\D/g, "")
                  setLeadPhone(cleanedValue)
                }}
              >
                {(inputProps) => (
                  <Input {...inputProps} placeholder="Phone Number" />
                )}
              </InputMask>
            </Form.Item>

            <Form.Item
              name="zipcode"
              label="Zipcode"
              rules={[
                {
                  pattern: new RegExp("^[0-9]{5}"),
                  message: "Not a valid zipcode.",
                },
              ]}
            >
              <Input
                placeholder="Zipcode"
                maxLength={5}
                onChange={(event) => {
                  setZipcode(event.target.value);
                }}
                allowClear
              />
            </Form.Item>

            <Form.Item name="channel" label="Channel">
              <Select
                placeholder="Select the lead channel"
                allowClear={true}
                options={CHANNEL_OPTIONS}
                onChange={(value) => setChannel(value)}
              />
            </Form.Item>
          </div>
          <div className="mx-4">
            <Form.Item
              name="size"
              label="Size"
              rules={[
                {
                  required: true,
                  message: "Please select a container size.",
                },
              ]}
            >
              <Select
                placeholder="Select the container size"
                allowClear={true}
                options={SIZE_OPTIONS}
                onChange={(value) => setSize(value)}
              />
            </Form.Item>

            <Form.Item
              name="condition"
              label="Condition"
              rules={[
                {
                  required: true,
                  message: "Please select a container condition.",
                },
              ]}
            >
              <Select
                placeholder="Select the container condition"
                allowClear={true}
                options={CONDITION_OPTIONS}
                onChange={(value) => setCondition(value)}
              />
            </Form.Item>

            <Form.Item name="price" label="Price">
              <Input
                placeholder="Price"
                prefix="$"
                type="number"
                min="1"
                onChange={(event) => {
                  setPrice(event.target.value);
                }}
                value={price}
                allowClear
              />
            </Form.Item>

            <Form.Item name="deliveryFee" label="Delivery Fee">
              <Input
                placeholder="Delivery Fee"
                prefix="$"
                type="number"
                min="0"
                step="any"
                onChange={(event) => {
                  setDeliveryFee(event.target.value);
                }}
                value={deliveryFee}
                allowClear
              />
            </Form.Item>

            <Form.Item name="nearbyCity" label="Nearby City">
              <Input
                placeholder="Nearby City"
                onChange={(event) => {
                  setNearbyCity(event.target.value.trim());
                }}
              />
            </Form.Item>

            <Form.Item name="distance" label="Distance">
              <Input
                placeholder="Distance"
                suffix="miles"
                type="number"
                min="0"
                step="any"
                onChange={(event) => {
                  setDistanceInMile(event.target.value);
                }}
                allowClear
              />
            </Form.Item>

            <Form.Item
              name="notes"
              label="Notes"
              rules={[
                {
                  max: 200,
                  message: "Can't exceed 200 characters",
                },
              ]}
            >
              <TextArea
                allowClear
                onChange={(event) => {
                  setNotes(event.target.value.trim());
                }}
                autoSize
              />
            </Form.Item>
          </div>
        </div>

        <Form.Item className="text-center">
          <Button type="primary" htmlType="submit">
            Add lead
          </Button>
        </Form.Item>
        <Alert
          message={addLeadAlertMessage}
          className={`mt-3 ${addLeadAlertClassName}`}
        />
      </Form>
    </div>
  );
}

export default AddLead;
