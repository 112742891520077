import { useContext, useEffect, useState } from "react";
import {
  Table,
  DatePicker,
  Button,
  Space,
  Popconfirm,
  Tooltip,
  message,
  Modal,
  Divider,
  Select
} from "antd";
import AuthContext from "../../context/AuthContext";
import { Link } from "react-router-dom";
import {
  ClockCircleOutlined,
  CheckOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { LeadStatusTag } from "../Tags/LeadStatusTag";
import './Todos.css'

const TodosList = () => {
  const { authTokens, user } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;

  const [todos, setTodos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTodoId, setSelectedTodoId] = useState(null);
  const [newDueDate, setNewDueDate] = useState(null);
  const [dueDateModalVisible, setDueDateModalVisible] = useState(false);
  const [refreshTodos, setRefreshTodos] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    const fetchTodos = async () => {
      // Fetch all todos
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };


      const url =
        user.role === "super"
          ? selectedUser
          ? `${process.env.REACT_APP_BACKEND}/v1/todos/all?user_id=${selectedUser}`
          : `${process.env.REACT_APP_BACKEND}/v1/todos/all`
          : `${process.env.REACT_APP_BACKEND}/v1/todos`;

      setLoading(true);

      let response = await fetch(url, requestOptions);
      const data = await response.json();

      if (!data.error) {
        setTodos(data);
        getUserOptions(data);
      }
      setLoading(false);
    };

    fetchTodos();
  }, [accessToken, refreshTodos, user, selectedUser]);

  const getUserOptions = (todos) => {
    let options = [];
    let checked = new Set();
    for (let i = 0; i < todos.length; i++) {
      if (!checked.has(todos[i].user_id)) {
        let option = {
          value: todos[i].user_id,
          label: todos[i].todo_owner,
        };
        options.push(option);
        checked.add(todos[i].user_id);
      } else {
        continue;
      }
    }
    setUserOptions(options);
  };


  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf("day");
  };

  // Function to check if due date is past due
  const isPastDue = (dueDate) => {
    const today = new Date();
    const due = new Date(dueDate);
    return due < today;
  };

  const handleOpenModal = (todoId) => {
    setDueDateModalVisible(true);
    setSelectedTodoId(todoId);
  };

  const handleUpdateDueDateConfirm = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + accessToken);

    const adjustedDueDate = dayjs(newDueDate).endOf("day").toDate();
    let payload = {
      dueDate: adjustedDueDate,
    };

    const requestOptions = {
      method: "PATCH",
      headers: headers,
      credentials: "include",
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/todos/snooze/${selectedTodoId}`;

    try {
      let response = await fetch(url, requestOptions);
      if (response.ok) {
        message.success("Task due date updated");
        // Refresh todos
      } else {
        message.error("Failed to update task due date");
      }
    } catch (error) {
      console.error("Error updating task due date", error);
      message.error("Error updating task due date");
    } finally {
      setSelectedTodoId(null);
      setDueDateModalVisible(false);
      setRefreshTodos((prevState) => !prevState);
    }
  };

  const handleCompleteTask = async (todoId) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + accessToken);
    const requestOptions = {
      method: "PATCH",
      headers: headers,
      credentials: "include",
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/todos/complete/${todoId}`;
    try {
      let response = await fetch(url, requestOptions);
      if (response.ok) {
        message.success("Task completed");
        setRefreshTodos((prevState) => !prevState);
      } else {
        message.error("Failed to mark the task completed");
      }
    } catch (err) {
      console.log(err);
      message.error("Error marking task completed");
    }
  };

  const handleDelete = async (todoId) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + accessToken);
    const requestOptions = {
      method: "DELETE",
      headers: headers,
      credentials: "include",
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/todos/${todoId}`;
    try {
      let response = await fetch(url, requestOptions);
      if (response.ok) {
        message.success("Task deleted");
        setRefreshTodos((prevState) => !prevState);
      } else {
        message.error("Failed to delete the task");
      }
    } catch (err) {
      console.log(err);
      message.error("Error deleting task");
    }
  };

  const columns = [
    {
      title: "Lead Name",
      dataIndex: "lead_name",
      key: "lead_name",
      align: "center",
      fixed: "left",
      width: 180,
      render: (lead_name, record) => (
        <Link
          to={`/leads/${record.lead_id}`}
          state={{ from: "/tasks" }}
          style={{ color: "inherit", textDecoration: "none" }}
        >
          <span
            className={`hover-bold ${
              isPastDue(record.due_date) ? "text-danger" : ""
            }`}
          >
            {lead_name}
          </span>
        </Link>
      ),
    },
    {
      title: "Status",
      key: "lead_status",
      align: "center",
      dataIndex: "lead_status",
      width: 100,
      render: (status) => <LeadStatusTag status={status} />,
    },
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
      align: "center",
      ellipsis: true,
      render: (task, record) => (
        <Tooltip placement="topLeft" title={task}>
          <span className={isPastDue(record.due_date) ? "text-danger" : ""}>
            {task}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      align: "center",
      width: 150,
      defaultSortOrder: "ascend",
      sorter: (a, b) => new Date(a.due_date) - new Date(b.due_date),
      render: (dueDate) => (
        <span className={`${isPastDue(dueDate) ? "text-danger" : ""}`}>
          {new Date(dueDate).toLocaleDateString("en-US", {
            hour12: false,
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })}
        </span>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      align: "center",
      render: (record) => (
        <Space size="middle">
          {user.userID === record.user_id && (
            <Popconfirm
              title="Complete this task?"
              onConfirm={() => handleCompleteTask(record.id)}
            >
              <Button shape="circle" icon={<CheckOutlined />} />
            </Popconfirm>
          )}
          <Tooltip title="change due date">
            <Button
              type="dashed"
              shape="circle"
              icon={<ClockCircleOutlined />}
              onClick={() => handleOpenModal(record.id)}
            />
          </Tooltip>
          <Popconfirm
            title="Delete this task?"
            onConfirm={() => handleDelete(record.id)}
          >
            <Button type="danger" shape="circle" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "Task Owner",
      dataIndex: "todo_owner",
      key: "todo_owner",
      align: "center",
      width: 150,
      hidden: user.role !== "super",
    },
  ].filter((item) => !item.hidden);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="m-2">
      <h2 className="text-center my-4">Tasks</h2>
      <Divider />
      {user.role === "super" &&
        <Select
          showSearch
          style={{ width: "200px"}}
          placeholder="Task Owner"
          allowClear
          options={userOptions}
          filterOption={filterOption}
          onChange={(value) => setSelectedUser(value)}
        />
      }
      {user.role === "super" && <Divider />}
      <Table
        columns={columns}
        dataSource={todos}
        rowKey="id"
        scroll={{ x: 1200, y: "100%" }}
        loading={loading}
        locale={{ emptyText: "No tasks available" }}
        pagination={false}
      />
      <Modal
        title="Select a new due date"
        open={dueDateModalVisible}
        onOk={handleUpdateDueDateConfirm}
        centered={true}
        closeIcon={false}
        onCancel={() => setDueDateModalVisible(false)}
        className="text-center"
      >
        <DatePicker
          disabledDate={disabledDate}
          allowClear={false}
          value={newDueDate}
          size="large"
          onChange={(date) => setNewDueDate(date)}
        />
      </Modal>
    </div>
  );
};

export default TodosList;
