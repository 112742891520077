export const LEAD_STATUS_NEW         = "new"
export const LEAD_STATUS_ATTEMPTED   = "attempted"
export const LEAD_STATUS_QUOTE_SENT = "quote_sent";
export const LEAD_STATUS_CLOSED_WON = "closed_won";
export const LEAD_STATUS_CLOSED_LOST = "closed_lost";

export const LEAD_STATUS_VALUES = {
  "new" : "new lead",
  "attempted" : "attempted",
  "quote_sent" : "quote sent",
  "closed_won" : "closed won",
  "closed_lost" : "closed lost",
}
