import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Form, Input, Spin } from "antd";
import AuthContext from "../../context/AuthContext";

// todo: add loading

const EditLocation = () => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;

  const [allowEdit, setAllowEdit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();
  const { id } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/locations/${id}`;

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        form.setFieldsValue({ city: data.city, name: data.name, zipcode: data.zipcode });
        setLoading(false)
      } catch (err) {
        console.log(err);
        setLoading(false)
      }
    };

    fetchData();
  }, [accessToken, id, form]);

  const handleSubmit = async () => {
     const headers = new Headers();
     headers.append("Content-Type", "application/json");
     headers.append("Authorization", "Bearer " + accessToken);

     let payload = {
       locationId: parseInt(id),
       name: form.getFieldValue("name").trim(),
       city: form.getFieldValue("city").trim(),
       zipcode: form.getFieldValue("zipcode").trim(),
     };

     const requestOptions = {
      method: "PUT",
      headers: headers,
      credentials: "include",
      body: JSON.stringify(payload),
     }

    const url = `${process.env.REACT_APP_BACKEND}/v1/locations`;

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      if (data.error) {
        console.log(data.message);
        // setError(error);
      } else {
        // todo: success message
        console.log("update successfully");
        setAllowEdit(false)
      }
      // setLoading(false);
    } catch (err) {
      console.log(err);
      // setError(error);
      // setLoading(false);
    }
  }

  return (
    <div className="text-center">
      {loading ? (
        <Spin size="large" />
      ) : (
        <div className="m-2">
          <h3 className="text-center my-3">{form.getFieldValue("name")}</h3>
          <Form form={form} disabled={!allowEdit} name="edit-location" onFinish={handleSubmit}>
            <div className="d-flex justify-content-around">
              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: true,
                    message: "Please enter a city.",
                  },
                ]}
              >
                <Input
                  placeholder="Location City"
                  disabled
                  allowClear
                />
              </Form.Item>
            </div>

            <div className="d-flex justify-content-around">
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the name of the location.",
                  },
                ]}
              >
                <Input
                  placeholder="Location Name"
                  allowClear
                />
              </Form.Item>
            </div>

            <div className="d-flex justify-content-around">
              <Form.Item
                name="zipcode"
                label="Zip Code"
                rules={[
                  {
                    required: true,
                    message: "Please enter a zipcode.",
                  },
                  {
                    pattern: new RegExp("^[0-9]{5}"),
                    message: "Not a valid zipcode.",
                  },
                ]}
              >
                <Input
                  placeholder="Location Zipcode"
                  maxLength={5}
                  allowClear
                />
              </Form.Item>
            </div>

            {allowEdit && (
              <div className="d-flex justify-content-around">
                <Form.Item>
                  <Button className="mx-2" type="primary" htmlType="submit">
                    Update
                  </Button>
                  <Button onClick={() => setAllowEdit(false)}>Cancel</Button>
                </Form.Item>
              </div>
            )}
          </Form>
          {!allowEdit && (
            <div className="d-flex justify-content-around">
              <Button className="mx-2" onClick={() => setAllowEdit(true)}>
                Edit
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );

}

export default EditLocation
