import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { Divider, Table, Button, Modal, Space, Input } from "antd";
import { CITIES } from "../../utils/LocationUtils";
import { SearchOutlined } from "@ant-design/icons";

const InventoryList = () => {
  const [containers, setContainers] = useState([]);
  const { authTokens, userRole } = useContext(AuthContext);

  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState("");
  const [acted, setActed] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + authTokens.accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/containers/all?search_value=${searchValue}`;

      setLoading(true);

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        if (data.error) {
          console.log(data.message);
        } else {
          setContainers(data);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [searchValue, acted, authTokens]);

  const handleDelete = (id) => {
    const deleteInventory = async (id) => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + authTokens.accessToken);

      let requestOptions = {
        method: "DELETE",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/containers/${id}`;

      try {
        await fetch(url, requestOptions);
        // alert success
        console.log("deleted");
        setActed((prev) => !prev);
      } catch (err) {
        // alert failure
        console.log(err);
      }
    };

    deleteInventory(id);
  };

  const showModal = (inventory) => {
    setSelectedInventory(inventory);
    setOpen(true);
  };

  const handleOk = () => {
    handleDelete(selectedInventory.id);
    setSelectedInventory("");
    setOpen(false);
  };

  const handleCancel = () => {
    setSelectedInventory("");
    setOpen(false);
  };

  const columns = [
    {
      title: "City",
      key: "city",
      fixed: "left",
      align: "center",
      render: (container) => <>{CITIES[container.city]}</>,
    },
    {
      title: "Location",
      key: "location",
      align: "center",
      render: (container) => <>{container.locationName}</>,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      align: "center",
    },
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
      align: "center",
    },
    {
      title: "Quanity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
      render: (value) => <span>$ {value}</span>,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      align: "center",
      hidden: userRole === "super" || userRole === "admin" ? false : true,
      render: (container) => (
        <Space size="middle">
          <Button type="primary">
            <Link
              to={`/inventory/${container.id}`}
              style={{ textDecoration: "none" }}
            >
              Edit
            </Link>
          </Button>
          {userRole === "super" &&
            <Button type="primary" onClick={() => showModal(container)}>
              Delete
            </Button>
          }
        </Space>
      ),
    },
  ].filter((item) => !item.hidden);

  let lastUpdatedAt = new Date("2023-01-01T00:00:00.000");
  if (containers) {
    for (let i = 0; i < containers.length; i++) {
      if (new Date(containers[i].updatedAt) > lastUpdatedAt) {
        lastUpdatedAt = new Date(containers[i].updatedAt);
      }
    }
  }

  return (
    <div className="m-2">
      <div className="d-flex flex-wrap justify-content-between align-items-center">
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search by city, location, size, or condition "
          className="w-50"
          allowClear
          onChange={(event) => setSearchValue(event.target.value)}
        />
        {containers.length !== 0 && (
          <div className="mx-2">
            <em>
              Last sync at:{" "}
              {lastUpdatedAt.toLocaleString("en-US", { hour12: false })}
            </em>
          </div>
        )}
      </div>
      <Divider />
      <Table
        columns={columns}
        dataSource={containers}
        scroll={{ x: 800, y: "100%" }}
        rowKey="id"
        loading={loading}
      />
      <Modal
        title={`Are you sure you want to delete this inventory: ${selectedInventory.locationName}?`}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        closeIcon={false}
        okText="Yes"
        destroyOnClose={true}
      ></Modal>
    </div>
  );
};

export default InventoryList;
