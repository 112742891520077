import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Table, Divider, Tooltip, Select } from "antd";
import Alert from "../Alert";
import AuthContext from "../../context/AuthContext";
import { ArrowLeftOutlined } from "@ant-design/icons";

const LeadsFromHubspot = () => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resultSize, setResultSize] = useState(10);

  const [addLeadAlertMessage, setAddLeadAlertMessage] = useState("");
  const [addLeadAlertClassName, setAddLeadAlertClassName] = useState("d-none");

  const handlePullClick = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + accessToken);

    const requestOptions = {
      method: "GET",
      headers: headers,
      credentials: "include",
    };

    let url = `${process.env.REACT_APP_BACKEND}/v1/leads/hubspot?result_size=${resultSize}`;

    setLoading(true);

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      if (data.error) {
        console.log(data.error);
        setLeads([]);
      } else {
        setLeads(data);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleAddLead = (record) => {
    const addLead = async (record) => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      let payload = {
        leadName: record.leadName.trim(),
        leadStatus: "new",
        leadEmail: record.leadEmail ? record.leadEmail.trim() : null,
        leadPhone: record.leadPhone? record.leadPhone.trim() : null,
        size: record.size.toLowerCase().includes("40") ? "40HC" : "20ST",
        condition: record.condition.toLowerCase().includes("used")
          ? "CW"
          : "OT",
        destinationZipcode: record.destinationZipcode.trim(),
        notes: record.notes ? record.notes.trim() : null,
        channel: record.channel,
      };

      let requestOptions = {
        method: "POST",
        headers: headers,
        credentials: "include",
        body: JSON.stringify(payload),
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/leads`;

      setLoading(true);
      setAddLeadAlertClassName("d-none");
      setAddLeadAlertMessage("");

      try {
        const res = await fetch(url, requestOptions);
        const data = await res.json();
        if (data.error) {
          setAddLeadAlertClassName("alert-danger");
          setAddLeadAlertMessage(`failed adding lead, missing info`);
        } else {
          // show success message
        }
      } catch (error) {
        setAddLeadAlertClassName("alert-danger");
        setAddLeadAlertMessage(
          `failed adding lead, the error is: missing info`
        );
      }
    };

    addLead(record);
    handlePullClick();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "leadName",
      key: "leadName",
      align: "center",
      fixed: "left",
      width: 150,
    },
    {
      title: "Lead Exists?",
      key: "existDb",
      align: "center",
      width: 150,
      render: (record) => {
        if (record.existDb) {
          return <span className="fw-bold text-success">Yes</span>;
        } else {
          return (
            <div>
              <span className="fw-bold text-primary">No</span>
              <Button
                type="primary"
                size="small"
                className="mx-2"
                onClick={() => handleAddLead(record)}
              >
                Add
              </Button>
            </div>
          );
        }
      },
    },
    {
      title: "Email",
      dataIndex: "leadEmail",
      key: "leadEmail",
      align: "center",
      width: 250,
      ellipsis: true,
    },
    {
      title: "Phone",
      dataIndex: "leadPhone",
      key: "leadPhone",
      align: "center",
      width: 150,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      align: "center",
      width: 100,
    },
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
      align: "center",
      width: 100,
    },
    {
      title: "Zipcode",
      dataIndex: "destinationZipcode",
      key: "destinationZipcode",
      align: "center",
      width: 100,
    },
    {
      title: "Channel",
      key: "channel",
      dataIndex: "channel",
      align: "center",
      width: 150,
      render: (value) => {
        if (value === "facebook_leads") {
          return <>facebook leads</>;
        } else {
          return <>{value}</>;
        }
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      align: "center",
      width: 150,
      ellipsis: true,
      render: (notes) => (
        <Tooltip placement="topLeft" title={notes}>
          {notes}
        </Tooltip>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 150,
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (createdAt) =>
        new Date(createdAt).toLocaleDateString("en-US", {
          hour12: false,
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }),
    },
  ].filter((item) => !item.hidden);

  return (
    <div className="m-2">
      <Button>
        <Link to="/leads" style={{ textDecoration: "none" }}>
          <ArrowLeftOutlined /> back
        </Link>
      </Button>
      <h2 className="mb-4 text-center">Leads from Hubspot</h2>
      <Divider />
      <div className="d-flex flex-wrap align-items-center">
        <Select
          defaultValue={10}
          className="me-2"
          onChange={(val) => setResultSize(val)}
          options={[
            {
              value: 10,
              label: "10 recent leads",
            },
            {
              value: 20,
              label: "20 recent leads",
            },
            {
              value: 30,
              label: "30 recent leads",
            },
          ]}
        />
        <Button
          type="primary"
          onClick={handlePullClick}
        >
          Pull recent leads from Hubspot
        </Button>
        <Alert
          message={addLeadAlertMessage}
          className={`mx-3 m-auto ${addLeadAlertClassName}`}
        />
      </div>
      <Divider />
      <Table
        columns={columns}
        dataSource={leads}
        scroll={{ x: 1200, y: "100%" }}
        rowKey="leadName"
        loading={loading}
      />
    </div>
  );
};

export default LeadsFromHubspot;
