import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Tooltip } from "antd";
import { CITIES } from "../../utils/LocationUtils";
import "./Inventory.css";

const InventorySummaryTable = ({ searchValue, condition, isLoggedIn }) => {
  const [containers, setContainers] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: headers,
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/containers/summary?search_value=${searchValue}&condition=${condition}`;

      setLoading(true);

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        if (data.error) {
          console.log(data.message);
        } else {
          setContainers(data);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [searchValue, condition]);

  const columns = [
    {
      title: "City",
      key: "city",
      fixed: "left",
      align: "center",
      ellipsis: true,
      render: (container) => (
        <Tooltip placement="topLeft" title={CITIES[container.city]}>
          {isLoggedIn ? (
            <Link
              to={`/inventory/city/${container.city}`}
              style={{ textDecoration: "none" }}
            >
              <span className="hover-bold">{CITIES[container.city]}</span>
            </Link>
          ) : (
            CITIES[container.city]
          )}
        </Tooltip>
      ),
    },
    {
      title: (
        <Tooltip placement="topLeft" title="20ft Standard">
          20ST
        </Tooltip>
      ),
      dataIndex: "20st",
      key: "20st",
      align: "center",
    },
    // {
    //   title: (
    //     <Tooltip placement="topLeft" title="20ft High Cube">
    //       20HC
    //     </Tooltip>
    //   ),
    //   dataIndex: "20hc",
    //   key: "20hc",
    //   align: "center",
    // },
    // {
    //   title: (
    //     <Tooltip placement="topLeft" title="40ft Standard">
    //       40ST
    //     </Tooltip>
    //   ),
    //   dataIndex: "40st",
    //   key: "40st",
    //   align: "center",
    // },
    {
      title: (
        <Tooltip placement="topLeft" title="40ft High Cube">
          40HC
        </Tooltip>
      ),
      dataIndex: "40hc",
      key: "40hc",
      align: "center",
    },
    // {
    //   title: (
    //     <Tooltip placement="topLeft" title="40ft High Cube Double Door">
    //       40HCDD
    //     </Tooltip>
    //   ),
    //   dataIndex: "40hcdd",
    //   key: "40hcdd",
    //   align: "center",
    // },
  ].filter((item) => !item.hidden);

  let lastUpdatedAt = new Date("2023-01-01T00:00:00.000");
  if (containers) {
    for (let i = 0; i < containers.length; i++) {
      if (new Date(containers[i].updatedAt) > lastUpdatedAt) {
        lastUpdatedAt = new Date(containers[i].updatedAt);
      }
    }
  }

  return (
    <div className="m-2">
      <div className="text-end">
        {containers && containers.length !== 0 && (
          <div className="mx-2">
            <em>
              Last sync at:{" "}
              {lastUpdatedAt.toLocaleString("en-US", { hour12: false })}
            </em>
          </div>
        )}
      </div>
      <Table
        columns={columns}
        dataSource={containers}
        className="mt-3"
        // scroll={{ x: 1200, y: "100%" }}
        rowKey="city"
        loading={loading}
      />
    </div>
  );
};

export default InventorySummaryTable;
