import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import { useEffect, useState, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import {
  // AppstoreAddOutlined,
  FileSearchOutlined,
  DatabaseOutlined,
  HomeOutlined,
  EditOutlined,
  CarOutlined,
  ContactsOutlined,
  TeamOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";

function getItem(label, key, icon, type, children) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem(
    <Link to="/" style={{ textDecoration: "none" }}>
      Home
    </Link>,
    "/",
    <HomeOutlined />,
    "public"
  ),
  getItem(
    <Link to="/tasks" style={{ textDecoration: "none" }}>
      Tasks
    </Link>,
    "/tasks",
    <OrderedListOutlined />,
    "user"
  ),
  getItem(
    <Link to="/inventory" style={{ textDecoration: "none" }}>
      Inventory
    </Link>,
    "/inventory",
    <DatabaseOutlined />,
    "public"
  ),
  getItem(
    <Link to="/inventory/search" style={{ textDecoration: "none" }}>
      Search Inventory
    </Link>,
    "/inventory/search",
    <FileSearchOutlined />,
    "public"
  ),
  getItem(
    <Link to="/manage-inventory" style={{ textDecoration: "none" }}>
      Manage Inventory
    </Link>,
    "/manage-inventory",
    <EditOutlined />,
    "admin"
  ),
  getItem(
    <Link to="/leads" style={{ textDecoration: "none" }}>
      Leads
    </Link>,
    "/leads",
    <ContactsOutlined />,
    "user"
  ),
  getItem(
    <Link to="/locations" style={{ textDecoration: "none" }}>
      Locations
    </Link>,
    "/locations",
    <DatabaseOutlined />,
    "admin"
  ),
  getItem(
    <Link to="/drivers" style={{ textDecoration: "none" }}>
      Drivers
    </Link>,
    "/drivers",
    <CarOutlined />,
    "admin"
  ),
  getItem(
    <Link to="/admin/users" style={{ textDecoration: "none" }}>
      Users
    </Link>,
    "/admin/users",
    <TeamOutlined />,
    "super"
  ),
];

const NavMenu = () => {
  const [selectedKeys, setSelectedKeys] = useState([])
  const location = useLocation()
  const { userRole } = useContext(AuthContext);

  useEffect(() => {
    setSelectedKeys([location.pathname])
  }, [location])

  let menuItems = items
  switch (userRole) {
    case "super":
      menuItems = items
      break
    case "admin":
      menuItems = items.filter(item => item.type !== "super")
      break
    case "user":
      menuItems = items.filter(item => item.type !== "super" && item.type !== "admin")
      break
    default:
      menuItems = items.filter(item => item.type === "public")
  }

  return (
    <div>
      <Menu
        style={{
          backgroundColor: "#fff",
          color: "black",
        }}
        mode="inline"
        theme={"light"}
        items={menuItems}
        onSelect={(e) => {
          setSelectedKeys([e.key]);
        }}
        selectedKeys={selectedKeys}
      />
    </div>
  );

}

export default NavMenu
