import { useState } from "react";
import { Button, Divider, Form, Input, Select, Collapse, Space, Spin, Radio } from "antd";
import InventoryTable from "./InventoryTable";
import { LoadingOutlined } from "@ant-design/icons"
import { CONDITION_OPTIONS, SIZE_OPTIONS } from "../../utils/ContainerUtils";

const SearchInventory = () => {
  const [error, setError] = useState(null);
  const [zipcode, setZipcode] = useState("");
  const [size, setSize] = useState("");
  const [mileageRange, setMileageRange] = useState(200);
  const [condition, setCondition] = useState("");
  const [containers, setContainers] = useState([])
  const [collapsed, setCollapsed] = useState(false)
  const [searched, setSearched] = useState(false)
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()

  const handleSubmit = () => {
    setLoading(true);

    const getContainers = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      let requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${
        process.env.REACT_APP_BACKEND
      }/v1/containers/nearby?size=${size}&condition=${condition}&zipcode=${zipcode}&mileageRange=${mileageRange}`

      try {
        let response = await fetch(url, requestOptions)
        let data = await response.json()
        if (data.error) {
          console.log(data.message);
          setError(error)
          setContainers([])
        } else {
          // set data
          setContainers(data)
        }
        setLoading(false);
      } catch(err) {
        console.log(err);
        setContainers([]);
        setError(error)
        setLoading(false);
      }
    }

    getContainers()
    setCollapsed(true)
    setSearched(true)
  }

  const onReset = () => {
    form.resetFields()
    setMileageRange(200)
  }

  const collapseItems = [
    {
      key: "1",
      label: searched ? (
        <span className="fw-bold">Search Again</span>
      ) : (
        <span className="fw-bold">Search</span>
      ),
      onItemClick: () => {
        setSearched(false)
        setCollapsed((prev) => !prev)
      },
      children: (
        <Form form={form} name="search-inventory" onFinish={handleSubmit}>
          <Form.Item
            name="zipcode"
            rules={[
              {
                required: true,
                message: "Please enter a zipcode.",
              },
              {
                pattern: new RegExp("^[0-9]{5}"),
                message: "Not a valid zipcode.",
              },
            ]}
          >
            <Input
              placeholder="Enter the delivery zip code"
              maxLength={5}
              onChange={(event) => {
                setZipcode(event.target.value);
              }}
              allowClear
            />
          </Form.Item>

          <Form.Item
            name="size"
            rules={[
              {
                required: true,
                message: "Please select a container size.",
              },
            ]}
          >
            <Select
              placeholder="Select the container size"
              allowClear={true}
              options={SIZE_OPTIONS}
              onChange={(value) => setSize(value)}
            />
          </Form.Item>

          <Form.Item
            name="condition"
            rules={[
              {
                required: true,
                message: "Please select a container condition.",
              },
            ]}
          >
            <Select
              placeholder="Select the container condition"
              allowClear={true}
              options={CONDITION_OPTIONS}
              onChange={(value) => setCondition(value)}
            />
          </Form.Item>

          <Form.Item
            name="mileageRange"
          >
            <Radio.Group
              defaultValue={200}
              onChange={(e) => setMileageRange(e.target.value)}
            >
              <Radio.Button value={200}>200 miles</Radio.Button>
              <Radio.Button value={300}>300 miles</Radio.Button>
              <Radio.Button value={400}>400 miles</Radio.Button>
              <Radio.Button value={500}>500 miles</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
            </Space>
          </Form.Item>
        </Form>
      ),
    },
  ];


  return (
    <div className="m-2">
      <h2 className="my-4 text-center">Search Inventory</h2>
      <Divider />

      <div className="d-flex justify-content-center">
        <Collapse
          items={collapseItems}
          bordered={true}
          // defaultActiveKey={'1'}
          className="col-lg-6 col-md-8 col-sm-12"
          activeKey={collapsed ? [] : ["1"]}
        />
      </div>

      <Divider />

      {loading && (
        <div className="text-center">
          <Spin indicator={<LoadingOutlined />} size="large" />
        </div>
      )}

      {searched &&
        !loading &&
        (containers && containers.length !== 0 ? (
          <div className="text-center">
            <h2>Result</h2>
            <InventoryTable
              containers={containers}
              zipcode={zipcode}
              hideAction={false}
            />
          </div>
        ) : (
          <div className="text-center">
            <h2>Result</h2>
            <p className="fw-bold">
              No inventory within {mileageRange} miles, please check again later
            </p>
          </div>
        ))}
    </div>
  );
};

export default SearchInventory;
