import { LeadStatusTag } from "../components/Tags/LeadStatusTag";

export const SIZE_OPTIONS = [
  { value: "20ST", label: "20ft Standard" },
  { value: "20HC", label: "20ft High Cube" },
  { value: "40ST", label: "40ft Standard" },
  { value: "40HC", label: "40ft High Cube" },
  { value: "40HCDD", label: "40ft High Cube Double Door" },
];

export const CONDITION_OPTIONS = [
  { value: "OT", label: "One Trip" },
  { value: "CW", label: "Used" },
];

export const LEADSTATUS_OPTIONS = [
  { value: "new", label: <LeadStatusTag status="new" /> },
  { value: "attempted", label: <LeadStatusTag status="attempted" /> },
  { value: "quote_sent", label: <LeadStatusTag status="quote_sent" /> },
  { value: "closed_won", label: <LeadStatusTag status="closed_won" /> },
  { value: "closed_lost", label: <LeadStatusTag status="closed_lost" /> },
];

export const CHANNEL_OPTIONS = [
  { value: "facebook_leads", label: "Facebook Leads" },
  { value: "marketplace", label: "Marketplace" },
  { value: "webstore", label: "Webstore" },
  { value: "referral", label: "Referral" },
];
