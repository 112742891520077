import { useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  Select,
  Collapse,
  Space,
  Spin,
} from "antd";
import InventoryTable from "./InventoryTable";
import { LoadingOutlined } from "@ant-design/icons";
import { useFetchCities } from "../../utils/LocationUtils";
import { CONDITION_OPTIONS, SIZE_OPTIONS } from "../../utils/ContainerUtils";
import Alert from "../Alert";

const QuickQuoteModal = () => {
  const { cities, cityOptions } = useFetchCities();
  const [selectedCity, setSelectedCity] = useState();

  const [zipcode, setZipcode] = useState("");
  const [size, setSize] = useState("");
  const [condition, setCondition] = useState("");

  const [containers, setContainers] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [searched, setSearched] = useState(false);

  const [loading, setLoading] = useState(false);
  const [calculateAlertMessage, setCalculateAlertMessage] = useState("");
  const [calculateAlertClassName, setCalculateAlertClassName] =
      useState("d-none");

  const [form] = Form.useForm();

  const handleSubmit = () => {
    setLoading(true);

    const getContainers = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      let requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/containers/quote?location_id=${selectedCity.id}&size=${size}&condition=${condition}&to_zipcode=${zipcode}`;

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        if (data.error) {
          setCalculateAlertClassName("alert-danger");
          setCalculateAlertMessage("Can not get a quote. Try again later");
        } else {
          setContainers([data]);
          setCalculateAlertClassName("d-none");
          setCalculateAlertMessage("");
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        setCalculateAlertClassName("alert-danger");
        setCalculateAlertMessage("Can not get a quote. Try again later");
      }
    };

    getContainers();
    setCollapsed(true);
    setSearched(true);
  };

  const onReset = () => {
    form.resetFields();
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const collapseItems = [
    {
      key: "1",
      label: searched ? (
        <span className="fw-bold">Search Again</span>
      ) : (
        <span className="fw-bold">Search</span>
      ),
      onItemClick: () => {
        setSearched(false);
        setCollapsed((prev) => !prev);
      },
      children: (
        <Form form={form} name="search-inventory" onFinish={handleSubmit}>
          <Form.Item
            name="city"
            rules={[
              {
                required: true,
                message: "Please select a city.",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select city"
              allowClear={true}
              options={cityOptions}
              disabled={loading}
              filterOption={filterOption}
              onChange={(value) => setSelectedCity(cities[value])}
            />
          </Form.Item>

          <Form.Item
            name="zipcode"
            rules={[
              {
                required: true,
                message: "Please enter a zipcode.",
              },
              {
                pattern: new RegExp("^[0-9]{5}"),
                message: "Not a valid zipcode.",
              },
            ]}
          >
            <Input
              placeholder="Enter the delivery zip code"
              maxLength={5}
              onChange={(event) => {
                setZipcode(event.target.value);
              }}
              allowClear
            />
          </Form.Item>

          <Form.Item
            name="size"
            rules={[
              {
                required: true,
                message: "Please select a container size.",
              },
            ]}
          >
            <Select
              placeholder="Select the container size"
              allowClear={true}
              options={SIZE_OPTIONS}
              onChange={(value) => setSize(value)}
            />
          </Form.Item>

          <Form.Item
            name="condition"
            rules={[
              {
                required: true,
                message: "Please select a container condition.",
              },
            ]}
          >
            <Select
              placeholder="Select the container condition"
              allowClear={true}
              options={CONDITION_OPTIONS}
              onChange={(value) => setCondition(value)}
            />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
            </Space>
          </Form.Item>
        </Form>
      ),
    },
  ];

  return (
    <div className="m-2">
      <h3 className="my-2 text-center">Quote Calculator</h3>
      <Divider />

      <Collapse
        items={collapseItems}
        bordered={true}
        // defaultActiveKey={'1'}
        activeKey={collapsed ? [] : ["1"]}
      />

      <Divider />

      {loading && (
        <div className="text-center">
          <Spin indicator={<LoadingOutlined />} size="large" />
        </div>
      )}

      {searched && !loading && containers && containers.length !== 0 && (
        <div className="text-center">
          <h3>Result</h3>
          <InventoryTable
            containers={containers}
            zipcode={zipcode}
            hideAction={false}
          />
        </div>
      )}
      <Alert
        message={calculateAlertMessage}
        className={`mt-3 ${calculateAlertClassName}`}
      />
    </div>
  );
};

export default QuickQuoteModal;
