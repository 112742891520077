import { useState, useContext, useEffect } from "react";
import { Button, Form, Input, Divider, Select, DatePicker, Spin } from "antd";
import AuthContext from "../../context/AuthContext";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;

const AddTodo = ({ leadId, onSuccess, onRefresh, leadUserId }) => {
  const { authTokens, user } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;

  const [task, setTask] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [customTask, setCustomTask] = useState("");
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [loading, setLoading] = useState(false);

  // Update selectedUser when user.id changes
  useEffect(() => {
    setSelectedUser(user.userID);
  }, [user]);

  const handleTaskChange = (value) => {
    if (value === "custom") {
      setTask("custom");
    } else {
      setTask(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/users/task_owners/${leadUserId}`;

      setLoading(true);

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        getUserOptions(data);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [accessToken, leadUserId]);

  const getUserOptions = (users) => {
    let options = [];
    for (let i = 0; i < users.length; i++) {
      let option = {
        value: users[i].id,
        label:
          users[i].fullname !== " " ? users[i].fullname : users[i].username,
      };
      options.push(option);
    }
    setUserOptions(options);
  };

  const handleSubmit = () => {
    const newTask = task === "custom" ? customTask : task;
    // Ensure due date is set to the end of the day
    const adjustedDueDate = dayjs(dueDate).endOf("day").toDate();
    const addTodo = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      let payload = {
        task: newTask.trim(),
        dueDate: adjustedDueDate,
        leadID: parseInt(leadId),
        userID: parseInt(selectedUser),
      };

      let requestOptions = {
        method: "POST",
        headers: headers,
        credentials: "include",
        body: JSON.stringify(payload),
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/todos`;

      try {
        const res = await fetch(url, requestOptions);
        const data = await res.json();
        if (data.error) {
          console.log(data.message);
        } else {
          // Call the onSuccess function passed via prop
          onSuccess();
          onRefresh();
          // show success message then redirect
        }
      } catch (error) {
        // Handle the error
        console.error(error);
      }
    };

    addTodo();
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf("day");
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="m-2">
      <h3 className="mb-2 text-center">Add Task</h3>
      <Divider />
      {loading ? (
        <div className="text-center">
          <Spin indicator={<LoadingOutlined />} size="large" />
        </div>
      ) : (
        <Form onFinish={handleSubmit}>
          <Form.Item
            name="task"
            rules={[
              {
                required: true,
                message: "Please select a task.",
              },
            ]}
          >
            <Select
              placeholder="Select Task"
              onChange={handleTaskChange}
              allowClear
            >
              <Option value="call">Call</Option>
              <Option value="send email">Send Email</Option>
              <Option value="send text">Send Text</Option>
              <Option value="custom">Custom</Option>
            </Select>
          </Form.Item>

          {task === "custom" && (
            <Form.Item
              name="customTask"
              rules={[
                {
                  required: true,
                  message: "Please enter a custom task.",
                },
                {
                  max: 50,
                  message: "Custom task must be less than 50 characters.",
                },
              ]}
            >
              <Input
                placeholder="Custom Task"
                onChange={(e) => setCustomTask(e.target.value)}
                allowClear
              />
            </Form.Item>
          )}

          <Form.Item
            name="dueDate"
            rules={[
              {
                required: true,
                message: "Please select a due date.",
              },
            ]}
          >
            <DatePicker
              placeholder="Select Due Date"
              disabledDate={disabledDate}
              onChange={(date) => setDueDate(date)}
            />
          </Form.Item>

          {/* admin can add task for others */}
          {user && (user.role === "super" || user.role === "admin") && (
            <Form.Item
              name="assignee"
              rules={[
                {
                  required: true,
                  message: "Please select a assignee.",
                },
              ]}
              initialValue={selectedUser}
            >
              <Select
                showSearch
                allowClear
                placeholder="Assign to"
                options={userOptions}
                filterOption={filterOption}
                onChange={(value) => setSelectedUser(value)}
              />
            </Form.Item>
          )}

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default AddTodo;
