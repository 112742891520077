import { useState, useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Select,
  Divider,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import AuthContext from "../../context/AuthContext";
import { CONDITION_OPTIONS, SIZE_OPTIONS } from "../../utils/ContainerUtils";

const AddInventory = () => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;

  const [locationId, setLocationId] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [size, setSize] = useState("");
  const [condition, setCondition] = useState("");
  const [price, setPrice] = useState(0);
  const [locationOptions, setLocationOptions] = useState([])
  // const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/locations`;

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        // setLocations(data);
        getLocationOptions(data);
      } catch (err) {
        // todo
        console.log(err);
      }
    };

    fetchData();
  }, [accessToken]);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const getLocationOptions = (locations) => {
    let options = []
    for (let i = 0; i < locations.length; i++) {
      let option = {
        value: locations[i]["id"],
        label: locations[i]["name"]
      }
      options.push(option)
    }
    setLocationOptions(options)
  }

  const handleSubmit = () => {
    const addInventory = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      let payload = {
        locationId: locationId,
        size: size,
        condition: condition,
        quantity: parseInt(quantity),
        price: parseInt(price),
      };

      let requestOptions = {
        method: "POST",
        headers: headers,
        credentials: "include",
        body: JSON.stringify(payload),
      };

      const url = `${process.env.REACT_APP_BACKEND}/v1/containers`;

      try {
        const res = await fetch(url, requestOptions);
        const data = await res.json();
        if (data.error) {
          console.log(data.message);
        } else {
          // show success message then redirect

          // redirect to page
          navigate("/inventory", { replace: true });
        }
      } catch (error) {
        // do sth with the error
        // setAlertClassName("alert-danger");
        // setAlertMessage(error);
      }
    };

    addInventory();
  };

  return (
    <div className="m-2">
      <Button>
        <Link to="/manage-inventory" style={{ textDecoration: "none" }}>
          <ArrowLeftOutlined /> back
        </Link>
      </Button>
      <h2 className="mb-4 text-center">Add Inventory</h2>
      <Divider />

      <Form form={form} name="add-location" onFinish={handleSubmit}>
        <Form.Item
          name="location"
          rules={[
            {
              required: true,
              message: "Please select a location.",
            },
          ]}
        >
          <Select
            placeholder="Select the location"
            allowClear={true}
            options={locationOptions}
            onChange={(value) => setLocationId(value)}
          />
        </Form.Item>

        <Form.Item
          name="size"
          rules={[
            {
              required: true,
              message: "Please select a container size.",
            },
          ]}
        >
          <Select
            placeholder="Select the container size"
            allowClear={true}
            options={SIZE_OPTIONS}
            onChange={(value) => setSize(value)}
          />
        </Form.Item>

        <Form.Item
          name="condition"
          rules={[
            {
              required: true,
              message: "Please select a container condition.",
            },
          ]}
        >
          <Select
            placeholder="Select the container condition"
            allowClear={true}
            options={CONDITION_OPTIONS}
            onChange={(value) => setCondition(value)}
          />
        </Form.Item>

        <Form.Item
          name="quantity"
          rules={[
            {
              required: true,
              message: "Please enter a quantity.",
            },
          ]}
        >
          <Input
            placeholder="Quantity"
            type="number"
            min="1"
            onChange={(event) => {
              setQuantity(event.target.value);
            }}
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="price"
          rules={[
            {
              required: true,
              message: "Please enter a price.",
            },
          ]}
        >
          <Input
            placeholder="Price"
            prefix="$"
            type="number"
            min="1"
            onChange={(event) => {
              setPrice(event.target.value);
            }}
            allowClear
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddInventory;
